import api from "../api.js";
import ServiceResponse from "./ServiceResponse.js";

const InsuranceService = {
  /**
   * Finds an insurance record by ID.
   *
   * @param {number} id - insurance_id
   * @return {ServiceResponse}
   */
  find: async (id) => {
    try {
      const { data } = await api({
        url: `/api/insurance/${id}`,
      });

      return new ServiceResponse(true, data.data, null, null);
    } catch (e) {
      console.error("InsuranceService.find: ", e);
      return new ServiceResponse(false, null, e.message, e.errors);
    }
  },

  /**
   * Retrieves an insurer with the specified ID from the API.
   *
   * @param {number} id - insurance_id
   * @return {ServiceResponse}
   */
  insurer: async (id) => {
    try {
      const { data } = await api({
        url: `/api/insurers/${id}`,
      });

      return new ServiceResponse(true, data.data, null, null);
    } catch (e) {
      console.error("InsuranceService.insurer: ", e);
      return new ServiceResponse(false, null, e.message, e.errors);
    }
  },
  
  /**
   * Saves insurance for a given issue.
   *
   * @param {string} issueId
   * @param {object} formData
   * @return {ServiceResponse}
   */
  saveInsurance: async (issueId, formData) => {
    try {
      const { data } = await api({
        url: `/api/issues/${issueId}/insurance`,
        method: 'POST',
        formData
      });
      return new ServiceResponse(true, data.data, null, null);
    } catch (e) {
      console.error("InsuranceService.saveInsurance: ", e);
      return new ServiceResponse(false, null, e.message, e.errors);
    }
  },

  /**
   * Saves contact info for a given issue.
   *  
   * @param {string} issueId
   * @param {object} body
   * @return {ServiceResponse}
   */
  saveContactInfo: async (issueId, body) => {
    try {
      const { data } = await api({
        url: `/api/issues/${issueId}/insurance/contact-info`,
        method: 'POST',
        body
      });
      return new ServiceResponse(true, data.data, null, null);
    } catch (e) {
      console.error("InsuranceService.saveContactInfo: ", e);
      return new ServiceResponse(false, null, e.response.data.message || e.message, e.response.data.data);
    }
  },

  /**
   * Saves policy details for a given issue.
   * 
   * @param {string} issueId
   * @param {object} body
   * @return {ServiceResponse}
   */
  savePolicyDetails: async (issueId, body) => {
    try {
      const { data } = await api({
        url: `/api/issues/${issueId}/insurance/policy-details`,
        method: 'POST',
        body
      });
      return new ServiceResponse(true, data.data, null, null);
    } catch (e) {
      console.error("InsuranceService.savePolicyDetails: ", e);
      return new ServiceResponse(false, null, e.response.data.message || e.message, e.response.data.data);
    }
  },

  /**
   * Saves insurance payments for a given issue.
   * 
   * @param {string} issueId
   * @param {object} body
   * @return {ServiceResponse}
   */
  saveInsurancePayments: async (issueId, body) => {
    try {
      const { data } = await api({
        url: `/api/issues/${issueId}/insurance/payments`,
        method: 'POST',
        body
      });
      return new ServiceResponse(true, data.data, null, null);
    } catch (e) {
      console.error("InsuranceService.saveInsurancePayments: ", e);
      return new ServiceResponse(false, null, e.response.data.message || e.message, e.response.data.data);
    }
  },

  /**
   * Saves the EOB details for a specific issue.
   *
   * @param {string} issueId
   * @param {object} formData
   * @return {ServiceResponse}
   */
  saveEOBDetails: async (issueId, formData) => {
    try {
      const { data } = await api({
        url: `/api/issues/${issueId}/insurance/eob-details`,
        method: 'POST',
        formData
      });
      return new ServiceResponse(true, data.data, null, null);
    } catch (e) {
      console.error("InsuranceService.saveEOBDetails: ", e);
      return new ServiceResponse(false, null, e.response.data.message || e.message, e.response.data.data);
    }
  },
};

export default InsuranceService;