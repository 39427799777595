import { createApp } from 'vue'
import 'ant-design-vue/dist/reset.css'
import './scss/styles.scss'
import Antd from 'ant-design-vue'
import DatePicker from 'ant-design-vue/es/date-picker/moment'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import * as Sentry from '@sentry/vue'
import App from './App.vue'
import router from './router'
import { msalPlugin } from './util/plugins/msalPlugin'
import { msalInstance } from './util/plugins/authConfig.js'
import { pinia } from './stores'
import GoogleTracking from './util/tracking/google.js'

// import modules
import { registerModules } from './register-modules'
import exampleModule from './modules/example'
import coreModule from './modules/core'
import adminModule from './modules/admin'
import communicationModule from './modules/communication'
import responseToProviderModule from './modules/responseToProvider'
import followUpLetterModule from './modules/followUpLetter'
import declineProposalLetterModule from './modules/declineProposalLetter'

if ((new URLSearchParams(window.location.search)).get('tracking') !== 'false')
  GoogleTracking.enable()

registerModules({
  example: exampleModule,
  core: coreModule,
  admin: adminModule,
  communication: communicationModule,
  responseToProvider: responseToProviderModule,
  followUpLetter: followUpLetterModule,
  declineProposalLetter: declineProposalLetterModule,
})

const app = createApp(App)

Sentry.init({
  app,
  dsn: 'https://50df0c662974adb0330c038359f86475@o4507747509141504.ingest.us.sentry.io/4507747617669120',
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
    Sentry.browserProfilingIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', import.meta.env.VITE_URL],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  profilesSampleRate: 1.0,
  environment: import.meta.env.VITE_SENTRY_ENV || 'local',
  enabled: import.meta.env.VITE_ENV === 'development' || import.meta.env.VITE_ENV === 'production',
})

app
  .use(pinia)
  .use(router)
  .use(msalPlugin, msalInstance)
  .use(Antd)
  .component('a-moment-date-picker', DatePicker)
  .component('font-awesome-icon', FontAwesomeIcon)
  .mount('#app')
