import activities from '../assets/activities.json'
import steps from '../assets/steps.json'
import stages from '../assets/stages.json'
import type { Item, Stage } from '../types/progress'

export function findTitle(key: string): Item | null {
  const lowercaseKey = key.toLowerCase()

  const foundActivity = activities.find((item: Item) => item.key.toLowerCase() === lowercaseKey)
  if (foundActivity)
    return foundActivity

  const foundStep = steps.find((item: Item) => item.key.toLowerCase() === lowercaseKey)
  if (foundStep)
    return foundStep

  return null
}

export function findCardTitle(id: number): Stage | null {
  const foundStage = stages.find((item: Stage) => item.id === id)
  if (foundStage)
    return foundStage

  return null
}

export function findRouteByStep(title: string): string | undefined {
  const foundStep = steps.find((item: Item) => item.title.toLowerCase() === title.toLowerCase())
  if (foundStep)
    return foundStep.route

  return undefined
}

export function findRouteByActivity(title: string): string | undefined {
  const foundActivity = activities.find((item: Item) => item.title.toLowerCase() === title.toLowerCase())
  if (foundActivity)
    return foundActivity.route

  return undefined
}

export function findRoute(title: string): string | undefined {
  const foundStep = steps.find((item: Item) => item.title.toLowerCase() === title.toLowerCase())
  if (foundStep)
    return foundStep.route

  return undefined
}
