<script lang="ts" setup>
import {computed, onMounted, onUnmounted, reactive} from 'vue'
import Header from '@/components/HeaderNew.vue'
// import SubHeader from '@/view/issues/steps/SubHeader.vue'
import BreadcrumbHeader from '@/modules/core/components/headers/BreadcrumbHeader.vue'
import InitialProgress from '@/modules/core/components/progress/InitialProgress.vue'
import ProgressCard from '@/modules/core/components/progress/ProgressCard.vue'
import ProgressEmpty from '@/modules/core/components/progress/ProgressEmpty.vue'
import CompactProgressCard from '@/modules/core/components/progress/CompactProgressCard.vue'
import SkeletonProgress from '@/modules/core/components/progress/SkeletonProgress.vue'
import { useIssuesStore } from '@/stores/issuesStore'
import router from '@/router/index.js'
import ColoredResponse from '@/modules/core/components/legend/ColoredResponse.vue'
import OverchargesCard from '@/modules/core/components/OverchargesCard.vue'
import { useWindowSize } from '@/modules/core/composables/useWindowSize'
import FastTrackStart from '@/modules/core/components/fastTrack/FastTrackStart.vue'
import {webSocketManager} from "@/modules/core/composables/webSocketManager";

const issuesStore = useIssuesStore()
const { width } = useWindowSize()

const state = reactive({
  slug: router.currentRoute.value.params?.slug,
  path: computed(() => router.currentRoute.value.path),
  currentIssue: computed(() => issuesStore.currentIssue),
  loading: false,

  discardRoutes: {
    fastTrack: ['issues.fastTrack', 'issues.whoAreYou', 'issues.patientInformation', 'issues.providerDetails']
  }
})

const appVersion = import.meta.env.VITE_APP_VERSION

onMounted(async () => {
  webSocketManager.initialize()
  webSocketManager.subscribeToIssue(state.slug)
})

onUnmounted(() => {
  webSocketManager.unsubscribeFromIssue(state.slug)
})
</script>

<template>
  <a-config-provider :theme="{ token: { borderRadius: 2, colorPrimary: '#005e9e', orange: '#f59f00' } }">
    <section>
      <div class="container-nav">
        <Header />
      </div>
      <div class="bg-dashboard">
        <div class="container-pf margin-xs">
          <!-- <SubHeader /> -->
          <BreadcrumbHeader />
          <a-row :gutter="[15, 15]" class="mobile_row">
            <a-col
              :span="24" :order="2" :md="{ span: 24, order: 2 }" :lg="{ span: 16, order: 1 }"
              :xl="{ span: 16, order: 1 }" class="mobile_col"
            >
              <a-row :gutter="[15, 15]" class="mobile_row">
                <a-col :span="24" class="mobile_col">
                  <router-view v-slot="{ Component }">
                    <transition name="step" mode="out-in">
                      <component :is="Component" />
                    </transition>
                  </router-view>
                </a-col>
              </a-row>
            </a-col>
            <a-col
              :span="24" :order="1" :md="{ span: 24, order: 1 }" :lg="{ span: 8, order: 2 }"
              :xl="{ span: 8, order: 2 }" class="mobile_col"
            >
              <a-row :gutter="[15, 15]" class="mobile_row">
                <template v-if="state.currentIssue">
                  <a-col v-if="router.currentRoute.value.name === 'issues.responseEvaluatePbP'" :span="24" class="mobile_col">
                    <ColoredResponse title="Response options legend" />
                  </a-col>
                  <a-col v-if="router.currentRoute.value.name === 'issues.responseEvaluateSummary'" :span="24" class="mobile_col">
                    <OverchargesCard />
                  </a-col>
                  <a-col v-if="state.currentIssue?.progress && width >= 992" :span="24" class="mobile_col">
                    <ProgressCard :loading="state.loading" :data="state.currentIssue" />
                  </a-col>
                  <a-col v-if="state.currentIssue?.progress && width < 992" :span="24" class="mobile_col">
                    <CompactProgressCard :loading="state.loading" :data="state.currentIssue" />
                  </a-col>
                  <a-col v-if="!state.currentIssue?.progress" :span="24" class="mobile_col">
                    <ProgressEmpty />
                  </a-col>
                  <a-col
                    v-if="state.currentIssue?.status?.id === 1 && !state.discardRoutes.fastTrack.includes(router.currentRoute.value.name)"
                    :span="24" class="mobile_col"
                  >
                    <FastTrackStart />
                  </a-col>
                </template>
                <template v-else>
                  <a-col v-if="state.path === '/screening'" :span="24" class="mobile_col">
                    <InitialProgress />
                  </a-col>
                  <a-col v-else :span="24" class="mobile_col">
                    <SkeletonProgress />
                  </a-col>
                </template>
              </a-row>
            </a-col>
          </a-row>
        </div>
      </div>
      <div class="footer bg-white">
        <label>v{{ appVersion }}</label>
      </div>
    </section>
  </a-config-provider>
</template>

<style lang="scss" scoped>
@import "@/scss/issue-layout.scss";
</style>
