import type { RouteRecordRaw, Router } from 'vue-router'

const moduleRoute: RouteRecordRaw = {
  path: '/issues/:slug/follow-up-letter',
  meta: { title: "Case #" },
  children: [
    {
      path: '',
      name:'issues.beforeFollowUpLetter',
      component: () => import('../pages/BeforeFollowUpLetter.vue'),
      meta: {
        requiresAuth: true,
        layout: 'IssueLayout',
      },
    },
    {
      path: 'lack-of-response',
      name:'issues.lackOfResponse',
      component: () => import('../pages/LackOfResponse.vue'),
      meta: {
        requiresAuth: true,
        layout: 'IssueLayout',
      },
    },
  ],
}

export default (router: Router) => {
  router.addRoute(moduleRoute)
}