import type { RouteRecordRaw, Router } from 'vue-router'

const moduleRoute: RouteRecordRaw = {
  path: '/issues/:slug/response/evaluate/:id/decline-offer', // Adjust path as needed
  meta: { title: "Case #" },
  children: [
    {
      path: '', // Adjust child path if necessary, e.g., 'offer' if it's a direct child
      name: 'offer',
      component: () => import('../pages/DeclineProposalSelect.vue'),
      meta: {
        requiresAuth: true,
        layout: 'IssueLayout',
      },
    },
  ],
}

export default (router: Router) => {
  router.addRoute(moduleRoute)
}
