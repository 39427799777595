<script lang="ts" setup>
import { useWindowSize } from '@/modules/core/composables/useWindowSize'

const { width } = useWindowSize()
</script>

<template>
  <template v-if="width >= 992">
    <a-card class="card">
      <template #title>
        <a-row :wrap="false" :gutter="[12, 0]">
          <a-col flex="auto">
            <a-skeleton-button active block />
          </a-col>
          <a-col flex="none">
            <a-skeleton-avatar active />
          </a-col>
        </a-row>
      </template>

      <a-row v-for="index in 6" :key="index" :wrap="false" :gutter="12" align="middle" style="padding-bottom: 8px;">
        <a-col flex="none">
          <a-skeleton-avatar size="small" shape="square" active />
        </a-col>
        <a-col flex="auto">
          <a-skeleton-button size="small" active block />
        </a-col>
        <a-col flex="none">
          <a-skeleton-avatar active />
        </a-col>
      </a-row>
    </a-card>
  </template>

  <template v-else>
    <a-card class="card">
      <template #title>
        <a-space direction="horizontal" style="justify-content: center; width:100%; padding: 12px;">
          <a-card v-for="index in 3" :key="index" size="small">
            <a-row :wrap="false" :gutter="12" align="middle">
              <a-col flex="none">
                <a-skeleton-avatar active />
              </a-col>
              <a-col flex="auto">
                <a-skeleton-button size="small" active block />
              </a-col>
            </a-row>
          </a-card>
        </a-space>
      </template>
    </a-card>
  </template>
</template>

<style lang="scss" scoped>
@import "@/scss/steps.scss";
</style>
