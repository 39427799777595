import type { CurrencyFormatterOptions } from '../types/utils'

export function formatUSDCurrency(value: number, options: CurrencyFormatterOptions = {}): string {
  const { decimals = 0, sign = false } = options

  const formatterOptions: Intl.NumberFormatOptions = {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
    style: sign ? 'currency' : 'decimal',
    currency: sign ? 'USD' : undefined,
  }

  const formatter = new Intl.NumberFormat(undefined, formatterOptions)
  return formatter.format(value)
}
