import { getTokenSetLocal, parseJwt } from '../localTokenAccess.js'
import api from '../api.js'
import local from '../local-util.js'
import router from '../../router/index.js'
import { msalInstance } from '../plugins/authConfig.js'

const store = {
  isLoginInProcess: false,
  token: '',
  user: {
    id: '',
    person_id: '',
    email: '',
    has_cases: false,
  },
  issue: {
    id: '',
    title: '',
  },
}

const AuthService = {
  links: {
    login: 'https://patientfairnessauth.b2clogin.com/patientfairnessauth.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_SignUpSignIn&client_id=c28586ff-ed61-4996-9f3d-ab6718058cf2&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fapp.patientfairness.com%2F&scope=openid&response_type=id_token&prompt=login',
    register: 'https://patientfairnessauth.b2clogin.com/patientfairnessauth.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_SignUp&client_id=c28586ff-ed61-4996-9f3d-ab6718058cf2&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fapp.patientfairness.com%2F&scope=openid&response_type=id_token&prompt=login',
  },

  login: async () => {
    if (!store.isLoginInProcess) {
      store.isLoginInProcess = true

      const { accessToken, jwt } = getTokenSetLocal()
      const user = jwt

      store.token = accessToken

      const payload = {
        email: user.emails[0],
      }

      await api({
        url: '/auth/login',
        method: 'POST',
        token: accessToken,
        body: payload,
      }).then(async ({ data }) => {
        if (data.success) {
          store.user = {
            id: data.data.id,
            email: data.data.email,
            person_id: data.data.person_id,
            has_cases: data.data.has_cases,
          }

          local.set('id', data.data.person_id)

          if (AuthService.isNew()) {
            const newCase = local.get('newCase')

            if (!store?.user?.has_cases) {
              router.push('/screening')
            }
            else if (newCase && newCase.skipScreening == true)
              router.push('/before-started')
            else
              router.push('/screening')
          }
          else {
            const pageUrl = local.get('urlTo')

            if (!store?.user?.has_cases) {
              router.push('/screening')
            } else if (pageUrl && pageUrl !== '') {
              await router.replace(pageUrl)
              local.remove('urlTo')
            }
            else {
              await router.replace('/dashboard')
            }
          }
        }
        else {
          // ToDo: show a message something went wrong
        }
      }).catch((error) => {
        console.log(error)
        throw error
      })

      store.isLoginInProcess = false
      return store
    }
  },

  /**
   *
   * @returns {Promise<Result|boolean|any>}
   */
  isAuthenticated: async () => {
    return msalInstance.handleRedirectPromise().then((response) => {
      if (response !== null) {
        return true
      }
      else {
        const accounts = msalInstance.getAllAccounts()
        if (accounts.length > 0)
          return true
      }

      return false
    }).catch(() => {
      return false
    })
  },

  /**
   * Check if logged user is just registered
   *
   * @returns {boolean}
   */
  isNew: () => {
    const { accessToken } = getTokenSetLocal()
    const parsedToken = parseJwt(accessToken)

    return (Boolean)(parsedToken && parsedToken.newUser)
  },
}

export default AuthService
