import type { Router } from 'vue-router'
import router from './router'

function registerModule(module: Module) {
  if (module.router)
    module.router(router)
}

export function registerModules(modules: Modules) {
  Object.keys(modules).forEach((moduleKey) => {
    const module = modules[moduleKey]
    registerModule(module)
  })
}

interface Module { router: (arg0: Router) => void }
interface Modules { [key: string]: Module }
