export const proposalRoutes = [
    {
        path: 'proposal',
        name: 'issues.proposal',
        component: () => import('../pages/ReceiveProposal/ProposalLanding.vue'),
        meta: {
          requiresAuth: true,
          layout: 'IssueLayout',
        },
    },
    {
      path: 'proposal/evaluate/new',
      name: 'issues.ProposalEvaluateNew',
      component: () => import('../pages/ReceiveProposal/ProposalType.vue'),
      meta: {
          requiresAuth: true,
          layout: 'IssueLayout',
      },
    },
    {
      path: 'proposal/evaluate/:id/settlement-amount',
      name: 'issues.ProposalSettlementAmount',
      component: () => import('../pages/ReceiveProposal/ProposalShouldNotPay.vue'),
      meta: {
          requiresAuth: true,
          layout: 'IssueLayout',
      },
    },
    {
        path: 'proposal/evaluate/:id/price-review',
        name: 'issues.proposalEvaluatePriceReview',
        component: () => import('../pages/ReceiveProposal/ProposalPriceReview.vue'),
        meta: {
            requiresAuth: true,
            layout: 'IssueLayout',
        },
    },
    {
      path: 'proposal/evaluate/:id/decline-offer',
      name: 'issues.proposal.DeclineOffer',
      component: () => import ('../../declineProposalLetter/pages/DeclineProposalSelect.vue'),
      meta: {
          requiresAuth: true,
          layout: 'IssueLayout',
      },
    },
    {
      path: 'proposal/evaluate/:id/offer-payments',
      name: 'issues.proposalEvaluateOfferPayments',
      component: () => import('../pages/ResponseOfferPayments.vue'),
      meta: {
          requiresAuth: true,
          layout: 'IssueLayout',
      },
    },
    {
      path: 'proposal/evaluate/:id/authorize',
      name: 'issues.proposalEvaluateAuthorize',
      component: () => import ('../pages/ResponseAuthorize.vue'),
      meta: {
          requiresAuth: true,
          layout: 'IssueLayout',
      },
    },
    {
      path: 'proposal/evaluate/:id/max-affordable',
      name: 'issues.proposalEvaluateMaxAffordable',
      component: () => import ('../pages/ResponseMaxAffordable.vue'),
      meta: {
          requiresAuth: true,
          layout: 'IssueLayout',
      },
    },
    {
      path: 'proposal/evaluate/:id/max-settlement',
      name: 'issues.proposalEvaluateMaxSettlement',
      component: () => import('../pages/ResponseMaxSettlement.vue'),
      meta: {
          requiresAuth: true,
          layout: 'IssueLayout',
      },
    },
    {
      path: 'proposal/evaluate/:id/received-proposal',
      name: 'issues.proposalEvaluateReceivedProposal',
      component: () => import('../pages/ReceiveProposal/ProposalEvaluate.vue'),
      meta: {
          requiresAuth: true,
          layout: 'IssueLayout',
      },
    },
    {
      path: 'proposal/evaluate/:id/your-payments',
      name: 'issues.proposalEvaluateYourPayments',
      component: () => import('../pages/ResponseOfferPayments.vue'),
      meta: {
          requiresAuth: true,
          layout: 'IssueLayout',
      },
    },
]