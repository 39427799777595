import api from "../api.js";
import ServiceResponse from "./ServiceResponse.js";

const MetricsService = {
  getMetrics: async (id) => {
    try {
      const { data } = await api({
        url: `/api/issues/${id}/bill-metrics`,
      });

      return new ServiceResponse(true, data.data, null, null);
    } catch (e) {
      console.error("MetricsService.getMetrics: ", e);
      return new ServiceResponse(false, null, e.message, e.errors);
    }
  },
};

export default MetricsService;
