import api from "../api.js";
import ServiceResponse from "./ServiceResponse.js";

/**
 * DocumentsService
 */
const DocumentsService =  {
    /**
     * Get all documents from a given issue
     *
     * @param issue_id
     * @returns {Promise<ServiceResponse>}
     */
    allFromIssue: async (issue_id) => {
        try {
            const { data } = await api({
                url: `/api/documents/${issue_id}`,
                headers: {
                    Accept: '*/*',
                }
            });

            return new ServiceResponse(true, data.data, null, null);
        } catch (e) {
            console.error("DocumentsService.allFromIssue: ", e);

            return new ServiceResponse(false, null, e.message, e.errors);
        }
    },

    /**
     * Post documents
     *
     * @returns {Object|Error} data
     */
    store: async (issue_id, type, file, is_new_communication,pf_communicated_at) => {
        try {
            const formData = new FormData();

            formData.append('issue_id', issue_id);
            formData.append('type', type);
            formData.append('file', file);
            formData.append('is_new_communication', is_new_communication);
            formData.append('pf_communicated_at', pf_communicated_at);

            const { data } = await api({
                url: `/api/documents`,
                method: 'POST',
                formData
            });

            return new ServiceResponse(true, data.data, null, null);
        } catch (e) {
            console.error("DocumentsService.post: ", e);
            return new ServiceResponse(false, null, e.message, e.errors);
        }
    },

    /**
     * Posts single document
     *
     * @returns {Object|Error} data
     */
    storeSingle: async (issue_id,type,file,is_fast_tracked) => {
        try {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('type', type);

            if (is_fast_tracked) formData.append('is_fast_tracked', is_fast_tracked);

            const { data } = await api({
                url: `/api/documents/${issue_id}`,
                method: 'POST',
                formData
            });

            return new ServiceResponse(true, data.data, null, null);
        } catch (e) {
            console.error("DocumentsService.post: ", e);
            return new ServiceResponse(false, null, e.message, e.errors);
        }
    },

    /**
     * Delete a document
     *
     * @todo ServiceResponse
     *
     * @param issue_id
     * @param id
     * @returns {Promise<{data: undefined}|{error}>}
     */
    delete: async (issue_id, document_id) => {
        try {
            const {data} = await api({
                url: `/api/documents/`,
                method: 'DELETE',
                body: {
                    issue_id,
                    document_id
                }
            });
            return new ServiceResponse(true, data.data, null, null);
        } catch (error) {
            console.error("DocumentsService.post: ", e);
            return new ServiceResponse(false, null, e.message, e.errors);
        }
    },
    /**
     * Unlink document
     *
     * @todo ServiceResponse
     * @returns {Promise<{data: undefined}|{error}>}
     */
    unlink: async (document_id,documentable_type,documentable_id) => {
        try {
            //console.log(documentable_id,documentable_type);
            const {data} = await api({
                url: `api/documents/${document_id}/unlink`,
                method: 'DELETE',
                body: {
                    documentable_type,
                    documentable_id
                }
            });
            return new ServiceResponse(true, data.data, null, null);
        } catch (error) {
            console.error("DocumentsService.post: ", e);
            return new ServiceResponse(false, null, e.message, e.errors);
        }
    },
}

export default DocumentsService;