import {jwt} from "./jwt.js";

const local = {
  get(target) {
    return JSON.parse(localStorage.getItem(target));
  },
  set(target, payload) {
    localStorage.setItem(target, JSON.stringify(payload));
  },
  append(target, payload) {
    local.set(target, Object.assign(local.get(target) || {}, payload));
  },
  getAllStorage(token) {
    let storage = [];
    for (const key in localStorage) {
      if (typeof localStorage[key] === 'string') {
        if (localStorage[key].includes(token)) {
          try {
            storage[key] = JSON.parse(localStorage[key]);
          } catch (e) {
            continue;
          }
        }
      }
    }
    return storage;
  },
  remove(target) {
    localStorage.removeItem(target);
  },
  purge() {
    localStorage.clear();
  }
};

export default local;

// TODO: create functionality to look up and save information on vue watch

