<script lang="ts" setup>
import Icon, { DownOutlined } from '@ant-design/icons-vue'
import { computed, reactive } from 'vue'
import stages from '@/modules/core/assets/stages.json'
import activities from '@/modules/core/assets/activities.json'
import steps from '@/modules/core/assets/steps.json'
import { useWindowSize } from '@/modules/core/composables/useWindowSize'

const { width } = useWindowSize()
const state = reactive({
  expandedKeys: [activities[0].key],
  treeData: [
    {
      title: activities[0].title,
      key: activities[0].key,
      children: [
        {
          title: steps[0].title,
          key: steps[0].key,
          route: steps[0].route,
        },
      ],
    },
  ],
  showBody: false,
  activeCard: -1,
})

function goToSection(index: number) {
  state.showBody = true
  state.activeCard = index
}
</script>

<template>
  <template v-if="width >= 992">
    <a-card class="progress-card" :title="stages[0].title" style="margin-bottom: 15px;">
      <template #extra>
        <Icon>
          <template #component>
            <img src="/icons/circle-orange.svg" alt="O" style="padding-right: 4px;">
          </template>
        </Icon>
      </template>

      <template #default>
        <a-directory-tree v-model:expandedKeys="state.expandedKeys" :tree-data="state.treeData" :selectable="false" :show-icon="false" block-node>
          <template #switcherIcon="{ switcherCls }">
            <DownOutlined :class="switcherCls" />
          </template>
          <template #title="{ title }">
            <a-row :wrap="false" :gutter="[12, 0]" align="middle">
              <a-col flex="auto">
                {{ title }}
              </a-col>
              <a-col flex="none">
                <Icon>
                  <template #component>
                    <img src="/icons/circle-orange.svg" alt="O">
                  </template>
                </Icon>
              </a-col>
            </a-row>
          </template>
        </a-directory-tree>
      </template>
    </a-card>
  </template>

  <template v-else>
    <a-card class="progress-card">
      <template #title>
        <div class="progress-card__title">
          <a-card
            size="small" class="progress-card__inner"
            :style="{ borderColor: state.activeCard === 1 ? '#54C860' : '' }" @click="goToSection(1)"
          >
            <a-row :wrap="false" :gutter="[12, 0]" class="progress-card__inner-row">
              <a-col flex="none">
                <Icon>
                  <template #component>
                    <img src="/icons/circle-orange.svg" alt="O">
                  </template>
                </Icon>
              </a-col>
              <a-col flex="auto">
                <label style="white-space: normal;">
                  {{ stages[0].title }}
                </label>
              </a-col>
            </a-row>
          </a-card>
        </div>
      </template>
      <template v-if="state.showBody" #default>
        <a-directory-tree :tree-data="state.treeData" :selectable="false" :show-icon="false" block-node>
          <template #switcherIcon="{ switcherCls }">
            <DownOutlined :class="switcherCls" />
          </template>
          <template #title="{ title }">
            <a-row :wrap="false" :gutter="[12, 0]" align="middle">
              <a-col flex="auto">
                {{ title }}
              </a-col>
              <a-col flex="none">
                <Icon>
                  <template #component>
                    <img src="/icons/circle-orange.svg" alt="O">
                  </template>
                </Icon>
              </a-col>
            </a-row>
          </template>
        </a-directory-tree>
      </template>
    </a-card>
  </template>
</template>

<style lang="scss" scoped>
.progress-card {
  padding: 0;

  &__title {
    overflow-x: auto;
    white-space: nowrap;
    margin: 16px -8px;
  }

  &__inner {
    display: inline-block;
    margin: 0 8px;

    &-row {
      width: 180px;
      height: 30px;
      display: flex;
      align-items: center;
    }
  }
}
</style>
