import { computed, onBeforeMount, reactive, watch } from 'vue'
import { metricsOvercharges, options } from '../../../util/options'
import { formatUSDCurrency } from '../utils/currency'
import { useIssuesStore } from '@/stores/issuesStore'
import { useWindowSize } from '@/modules/core/composables/useWindowSize'


export function useMetrics() {
  const issuesStore = useIssuesStore()
  const { width } = useWindowSize()

  const tableState = reactive({
    loading: false,
    currentIssue: computed(() => issuesStore.currentIssue),

    is_insured: computed(() => issuesStore.currentIssue?.bill?.insurance_id !== null && issuesStore.currentIssue?.bill?.insurance?.eob !== null),
    is_gfe: computed(() => issuesStore.currentIssue?.bill?.gfe?.total_amount !== null),
    is_agreement: computed(() => issuesStore.currentIssue?.agreement !== null),
    is_info: computed(() => issuesStore.currentIssue?.is_provider_covered_by_insurance !== null && issuesStore.currentIssue?.patient_payment_amount !== null),

    // insured
    cellB15: computed(() => issuesStore.currentIssue?.bill?.total - issuesStore.currentIssue?.bill?.insurance_adjustments - issuesStore.currentIssue?.bill?.patient_discounts),
    cellD15: computed(() => issuesStore.currentIssue?.bill?.insurance?.eob?.provider_total_amount - issuesStore.currentIssue?.bill?.insurance?.eob?.provider_discount_amount),
    cellD21: computed(() => issuesStore.currentIssue?.bill?.insurance?.eob?.coinsurance_owed_amount + issuesStore.currentIssue?.bill?.insurance?.eob?.deductible_owed_amount + issuesStore.currentIssue?.bill?.insurance?.eob?.copay_owed_amount),

    // non-insured
    cellB42: computed(() => issuesStore.currentIssue?.bill?.total - issuesStore.currentIssue?.bill?.patient_discounts),

    // overcharges
    overcharges: [],
    // formulas
    formulas: [] as any[],
    // highlighted cells
    highlighted: [],
  })

  const scrollValue = computed(() => {
    if (width.value < 530)
      return 'calc(120%)'
    else
      return 'calc(100%)'
  })

  const columns = [
    { title: 'Medical Bill', width: '20%', dataIndex: 'bill', key: 'bill', customCell: getCustomCellTitle },
    { title: 'Insurance EOB', width: '20%', dataIndex: 'eob', key: 'eob', customCell: getCustomCell('eob') },
    { title: 'Good Faith Estimate', width: '20%', dataIndex: 'gfe', key: 'gfe', customCell: getCustomCell('gfe') },
    { title: 'Agreement between Patient & Provider', width: '20%', dataIndex: 'agreements', key: 'agreements', customCell: getCustomCell('agreements') },
    { title: 'Customer Information', width: '20%', dataIndex: 'info', key: 'info', customCell: getCustomCell('info') },
  ]

  const isValue = (value: any) => {
    return typeof value === 'number' ? formatUSDCurrency(value) : value || null
  }

  const highlightCells = (cells: any) => {
    tableState.highlighted = cells
    issuesStore.highlightedCells = cells
  }

  const tableColumns = computed(() =>
    tableState.is_insured
      ? columns.filter(column =>
        (!tableState.is_gfe ? column.dataIndex !== 'gfe' : column.dataIndex)
        && (!tableState.is_agreement ? column.dataIndex !== 'agreements' : column.dataIndex)
        && (!tableState.is_info ? column.dataIndex !== 'info' : column.dataIndex),
      )
      : columns.filter(column =>
        (column.dataIndex !== 'eob')
        && (!tableState.is_gfe ? column.dataIndex !== 'gfe' : column.dataIndex)
        && (!tableState.is_agreement ? column.dataIndex !== 'agreements' : column.dataIndex)
        && (!tableState.is_info ? column.dataIndex !== 'info' : column.dataIndex),
      ),
  )

  const insuredData = computed(() => [
    {
      key: '0',
      bill: 'In-Network Benefits (Y/N)',
    },
    {
      key: '1',
      eob: {
        cellId: 'D8',
        value: options[tableState.currentIssue?.bill?.insurance?.is_network_prices],
      },
      info: {
        cellId: 'J8',
        value: options[tableState.currentIssue?.is_provider_covered_by_insurance],
      },
    },
    {
      key: '2',
      bill: 'Total Charges (before adjustments)',
    },
    {
      key: '3',
      bill: {
        cellId: 'B10',
        value: tableState.currentIssue?.bill?.total,
      },
      eob: {
        cellId: 'D10',
        value: tableState.currentIssue?.bill?.insurance?.eob?.provider_total_amount,
      },
    },
    {
      key: '4',
      bill: 'Insurance Discount/Adjustments',
    },
    {
      key: '5',
      bill: {
        cellId: 'B12',
        value: tableState.currentIssue?.bill?.insurance_adjustments,
      },
      eob: {
        cellId: 'D12',
        value: tableState.currentIssue?.bill?.insurance?.eob?.provider_discount_amount,
      },
    },
    {
      key: '6',
      bill: 'Patient Discount/Adjustments',
    },
    {
      key: '7',
      bill: {
        cellId: 'B13',
        value: tableState.currentIssue?.bill?.patient_discounts,
      },
      info: {
        cellId: '',
        value: undefined,
      },
    },
    {
      key: '8',
      bill: 'Total Charges (after adjustments)',
    },
    {
      key: '9',
      bill: {
        cellId: 'B15',
        value: tableState.cellB15,
      },
      eob: {
        cellId: 'D15',
        value: tableState.cellD15,
      },
      gfe: {
        cellId: 'F15',
        value: tableState.currentIssue?.bill?.gfe?.total_amount,
      },
      agreements: {
        cellId: 'H15',
        value: tableState.currentIssue?.agreement?.type === 'total_charges' ? tableState.currentIssue?.agreement?.amount : undefined,
      },
    },
    {
      key: '10',
      bill: 'Insurance Payments',
    },
    {
      key: '11',
      bill: {
        cellId: 'B17',
        value: tableState.currentIssue?.bill?.insurance_payments,
      },
      eob: {
        cellId: 'D17',
        value: tableState.currentIssue?.bill?.insurance?.eob?.insurance_amount,
      },
    },
    {
      key: '12',
      bill: 'Insurance Not Covered',
    },
    {
      key: '13',
      eob: {
        cellId: 'D19',
        value: tableState.currentIssue?.bill?.insurance?.eob?.not_covered_amount,
      },
    },
    {
      key: '14',
      bill: 'Owed by Patient under Insurance (incl. deductible, copay & coinsurance)',
    },
    {
      key: '15',
      eob: {
        cellId: 'D21',
        value: tableState.cellD21,
      },
    },
    {
      key: '16',
      bill: 'Owed by Patient - Total, before any patient payments',
    },
    {
      key: '17',
      agreements: {
        cellId: 'H26',
        value: tableState.currentIssue?.agreement?.type === 'amount_owed' ? tableState.currentIssue?.agreement?.amount : undefined,
      },
    },
    {
      key: '18',
      bill: 'Patient Payments already made (including funds from HRAs and HSAs)',
    },
    {
      key: '19',
      bill: {
        cellId: 'B28',
        value: tableState.currentIssue?.bill?.patient_payments,
      },
      eob: {
        cellId: 'D28',
        value: tableState.currentIssue?.bill?.insurance?.eob?.patient_paid_amount,
      },
      info: {
        cellId: 'J28',
        value: tableState.currentIssue?.patient_payment_amount,
      },
    },
    {
      key: '20',
      bill: 'Balance Due from Patient',
    },
    {
      key: '21',
      bill: {
        cellId: 'B30',
        value: tableState.currentIssue?.bill?.remaining_balance,
      },
      eob: {
        cellId: 'D30',
        value: tableState.currentIssue?.bill?.insurance?.eob?.patient_owed_amount,
      },
    },
  ])

  const nonInsuredData = computed(() => [
    {
      key: '0',
      bill: 'Total Charges (before adjustments)',
    },
    {
      key: '1',
      bill: {
        cellId: 'B39',
        value: tableState.currentIssue?.bill?.total,
      },
      info: {
        cellId: '',
        value: undefined,
      },
    },
    {
      key: '2',
      bill: 'Patient Discount/Adjustments',
    },
    {
      key: '3',
      bill: {
        cellId: 'B40',
        value: tableState.currentIssue?.bill?.patient_discounts,
      },
      info: {
        cellId: '',
        value: undefined,
      },
    },
    {
      key: '4',
      bill: 'Total Charges (after adjustments)',
    },
    {
      key: '5',
      bill: {
        cellId: 'B42',
        value: tableState.cellB42,
      },
      gfe: {
        cellId: 'D42',
        value: tableState.currentIssue?.bill?.gfe?.total_amount,
      },
      agreements: {
        cellId: 'F42',
        value: tableState.currentIssue?.agreement?.type === 'total_charges' ? tableState.currentIssue?.agreement?.amount : undefined,
      },
    },
    {
      key: '6',
      bill: 'Patient Payments already made',
    },
    {
      key: '7',
      bill: {
        cellId: 'B44',
        value: tableState.currentIssue?.bill?.patient_payments,
      },
      info: {
        cellId: 'H44',
        value: tableState.currentIssue?.patient_payment_amount,
      },
    },
    {
      key: '8',
      bill: 'Balance Due from Patient',
    },
    {
      key: '9',
      bill: {
        cellId: 'B46',
        value: tableState.currentIssue?.bill?.remaining_balance,
      },
      agreements: {
        cellId: 'F46',
        value: tableState.currentIssue?.agreement?.type === 'amount_owed' ? tableState.currentIssue?.agreement?.amount : undefined,
      },
    },
  ])

  function getCustomCellTitle(record: any) {
    const { eob, gfe, agreements, info } = record
    return {
      colspan: !eob && !gfe && !agreements && !info ? 5 : 1,
      style: {
        fontStyle: !eob && !gfe && !agreements && !info ? 'italic' : '',
        textAlign: !eob && !gfe && !agreements && !info ? 'left' : 'right',
        background: record?.bill ? '#fff' : '#e3e3e3',
        height: '40px',
      },
    }
  }

  function getCustomCell(fieldName: string) {
    return function (record: any) {
      const { eob, gfe, agreements, info } = record
      return {
        style: {
          display: !(eob || gfe || agreements || info) ? 'none' : '',
          textAlign: !(eob || gfe || agreements || info) ? 'left' : 'right',
          background: record[fieldName]?.value === null || record[fieldName]?.value === undefined ? '#e3e3e3 !important' : '#fff',
          height: '40px',
        },
      }
    }
  }

  const filterOvercharges = computed(() => {
    return metricsOvercharges.filter((item: any) => {
      if (tableState.is_insured) {
        if (item.key === 'LM61' && tableState.cellB15 !== null && tableState.currentIssue?.bill?.gfe?.total_amount !== null && (tableState.cellB15 - tableState.currentIssue?.bill?.gfe?.total_amount)) {
          tableState.formulas.push({ key: 'LM61', value: `${isValue(tableState.cellB15)} - ${isValue(tableState.currentIssue?.bill?.gfe?.total_amount)} = ${isValue(tableState.cellB15 - tableState.currentIssue?.bill?.gfe?.total_amount)}` })
          return true
        }
        if (item.key === 'LM64' && tableState.cellB15 !== null && tableState.currentIssue?.agreement?.type === 'total_charges' && tableState.currentIssue?.agreement?.amount !== null && (tableState.cellB15 - (tableState.currentIssue?.agreement?.type === 'total_charges' ? tableState.currentIssue?.agreement?.amount : undefined))) {
          tableState.formulas.push({ key: 'LM64', value: `${isValue(tableState.cellB15)} - ${tableState.currentIssue?.agreement?.type === 'total_charges' ? isValue(tableState.currentIssue?.agreement?.amount) : undefined} = ${isValue(tableState.cellB15 - (tableState.currentIssue?.agreement?.type === 'total_charges' ? tableState.currentIssue?.agreement?.amount : undefined))}` })
          return true
        }
        if (item.key === 'LM65' && tableState.currentIssue?.bill?.remaining_balance !== null && tableState.currentIssue?.agreement?.type === 'amount_owed' && tableState.currentIssue?.agreement?.amount !== null && tableState.currentIssue?.patient_payment_amount !== null && (tableState.currentIssue?.bill?.remaining_balance - ((tableState.currentIssue?.agreement?.type === 'amount_owed' ? tableState.currentIssue?.agreement?.amount : undefined) - tableState.currentIssue?.patient_payment_amount !== null ? tableState.currentIssue?.patient_payment_amount : tableState.currentIssue?.bill?.patient_payments))) {
          tableState.formulas.push({ key: 'LM65', value: `${isValue(tableState.currentIssue?.bill?.remaining_balance)} - ${tableState.currentIssue?.agreement?.type === 'amount_owed' ? isValue(tableState.currentIssue?.agreement?.amount) : undefined} - ${tableState.currentIssue?.patient_payment_amount !== null ? isValue(tableState.currentIssue?.patient_payment_amount) : isValue(tableState.currentIssue?.bill?.patient_payments)} = ${isValue(tableState.currentIssue?.bill?.remaining_balance - (tableState.currentIssue?.agreement?.type === 'amount_owed' ? tableState.currentIssue?.agreement?.amount : undefined) - tableState.currentIssue?.patient_payment_amount !== null ? tableState.currentIssue?.patient_payment_amount : tableState.currentIssue?.bill?.patient_payments)}` })
          return true
        }
        if (item.key === 'L67' && tableState.currentIssue?.bill?.remaining_balance !== null && tableState.currentIssue?.bill?.patient_payments !== null && tableState.currentIssue?.bill?.insurance?.eob?.patient_owed_amount !== null && tableState.currentIssue?.bill?.insurance?.eob?.patient_paid_amount !== null && ((tableState.currentIssue?.bill?.remaining_balance - tableState.currentIssue?.bill?.patient_payments) - (tableState.currentIssue?.bill?.insurance?.eob?.patient_owed_amount - tableState.currentIssue?.bill?.insurance?.eob?.patient_paid_amount))) {
          tableState.formulas.push({ key: 'L67', value: `(${isValue(tableState.currentIssue?.bill?.remaining_balance)} - ${isValue(tableState.currentIssue?.bill?.patient_payments)}) - (${isValue(tableState.currentIssue?.bill?.insurance?.eob?.patient_owed_amount)} - ${isValue(tableState.currentIssue?.bill?.insurance?.eob?.patient_paid_amount)}) = ${isValue((tableState.currentIssue?.bill?.remaining_balance - tableState.currentIssue?.bill?.patient_payments) - (tableState.currentIssue?.bill?.insurance?.eob?.patient_owed_amount - tableState.currentIssue?.bill?.insurance?.eob?.patient_paid_amount))}` })
          return true
        }
        if (item.key === 'LM70' && tableState.currentIssue?.patient_payment_amount !== null && tableState.currentIssue?.bill?.patient_payments !== null && (tableState.currentIssue?.patient_payment_amount - tableState.currentIssue?.bill?.patient_payments)) {
          tableState.formulas.push({ key: 'LM70', value: `${isValue(tableState.currentIssue?.patient_payment_amount)} - ${isValue(tableState.currentIssue?.bill?.patient_payments)} = ${isValue(tableState.currentIssue?.patient_payment_amount - tableState.currentIssue?.bill?.patient_payments)}` })
          return true
        }
      }
      else {
        if (item.key === 'LM61' && tableState.cellB42 !== null && tableState.currentIssue?.bill?.gfe?.total_amount !== null && (tableState.cellB42 - tableState.currentIssue?.bill?.gfe?.total_amount)) {
          tableState.formulas.push({ key: 'LM61', value: `${isValue(tableState.cellB42)} - ${isValue(tableState.currentIssue?.bill?.gfe?.total_amount)} = ${isValue(tableState.cellB42 - tableState.currentIssue?.bill?.gfe?.total_amount)}` })
          return true
        }
        if (item.key === 'LM64' && tableState.cellB42 !== null && tableState.currentIssue?.agreement?.type === 'total_charges' && tableState.currentIssue?.agreement?.amount !== null && (tableState.cellB42 - (tableState.currentIssue?.agreement?.type === 'total_charges' ? tableState.currentIssue?.agreement?.amount : undefined))) {
          tableState.formulas.push({ key: 'LM64', value: `${isValue(tableState.cellB42)} - ${tableState.currentIssue?.agreement?.type === 'total_charges' ? isValue(tableState.currentIssue?.agreement?.amount) : undefined} = ${isValue(tableState.cellB42 - (tableState.currentIssue?.agreement?.type === 'total_charges' ? tableState.currentIssue?.agreement?.amount : undefined))}` })
          return true
        }
        if (item.key === 'LM65' && tableState.currentIssue?.bill?.remaining_balance !== null && tableState.currentIssue?.agreement?.type === 'amount_owed' && tableState.currentIssue?.agreement?.amount !== null && tableState.currentIssue?.patient_payment_amount !== null && (tableState.currentIssue?.bill?.remaining_balance - ((tableState.currentIssue?.agreement?.type === 'amount_owed' ? tableState.currentIssue?.agreement?.amount : undefined) - tableState.currentIssue?.patient_payment_amount !== null ? tableState.currentIssue?.patient_payment_amount : tableState.currentIssue?.bill?.patient_payments))) {
          tableState.formulas.push({ key: 'LM65', value: `${isValue(tableState.currentIssue?.bill?.remaining_balance)} - ${tableState.currentIssue?.agreement?.type === 'amount_owed' ? isValue(tableState.currentIssue?.agreement?.amount) : undefined} - ${tableState.currentIssue?.patient_payment_amount !== null ? isValue(tableState.currentIssue?.patient_payment_amount) : isValue(tableState.currentIssue?.bill?.patient_payments)} = ${isValue(tableState.currentIssue?.bill?.remaining_balance - (tableState.currentIssue?.agreement?.type === 'amount_owed' ? tableState.currentIssue?.agreement?.amount : undefined) - tableState.currentIssue?.patient_payment_amount !== null ? tableState.currentIssue?.patient_payment_amount : tableState.currentIssue?.bill?.patient_payments)}` })
          return true
        }
        if (item.key === 'LM70' && tableState.currentIssue?.patient_payment_amount !== null && tableState.currentIssue?.bill?.patient_payments !== null && (tableState.currentIssue?.patient_payment_amount - tableState.currentIssue?.bill?.patient_payments)) {
          tableState.formulas.push({ key: 'LM70', value: `${isValue(tableState.currentIssue?.patient_payment_amount)} - ${isValue(tableState.currentIssue?.bill?.patient_payments)} = ${isValue(tableState.currentIssue?.patient_payment_amount - tableState.currentIssue?.bill?.patient_payments)}` })
          return true
        }
      }

      return false
    })
  })

  const getUniqueValues = (key: string) => {
    const uniqueValues = Array.from(new Set(tableState.formulas
      .filter(formula => formula.key === key)
      .map(formula => formula.value),
    ))

    return uniqueValues.join(', ')
  }

  onBeforeMount(() => {
    tableState.overcharges = filterOvercharges.value;
  });
  
  watch(filterOvercharges, (newFilterOvercharges) => {
    tableState.overcharges = newFilterOvercharges;
  })

  return {
    tableState,
    scrollValue,
    insuredData,
    nonInsuredData,
    highlightCells,
    tableColumns,
    filterOvercharges,
    getUniqueValues,
    isValue,
  }
}
