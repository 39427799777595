<template>
  <a-page-header class="subheader">
    <a-breadcrumb>
      <a-breadcrumb-item>
        <router-link to="/dashboard">Dashboard</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item v-if="state.slug">
        <router-link :to="'/issues/' + state.slug">Case #{{ state.slug }}</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>{{ state.pathAfter === "Screening" ? "How can we help?" : state.pathAfter }}</a-breadcrumb-item>
    </a-breadcrumb>
    <br />

    <a-row justify="space-between" class="title">
      <a-col :span="24" :sm="{ span: 24 }" style="text-transform: uppercase;"> <!-- set span to 12 if 2 or more columns -->
        <h4 v-if="(state.title === '-' || state.title === '- ') || !state.title">Problem Medical Bill Assessment</h4>
        <h4 v-else-if="state.slug && (state.title !== '-' || state.title !== '- ')">
          {{ state.title ? state.title : 'Loading...' }}</h4>
        <h4 v-else-if="state.pathAfter === 'Ineligible'">Ineligible</h4>
        <h4 v-else-if="state.pathAfter === 'Before Started' || state.pathAfter === 'Screening'">Problem Medical Bill Assessment</h4>
        <h4 v-else-if="state.pathAfter === 'Next Steps'">{{ state.title ? state.title : 'Loading...' }}</h4>
        <h4 v-else>New Case</h4>
      </a-col>
      <a-col :span="24" :sm="{ span: 12 }" class="title_item" v-if="state.slug">
        <!-- <router-view>
          <a-button type="primary" size="large" class="orange title_item_btn">
            <span>Save & Finish Later</span>
            <template #icon>
              <SaveOutlined :style="{ fontSize: '24px' }" />
            </template>
          </a-button>
        </router-view> -->
      </a-col>
    </a-row>
  </a-page-header>
</template>

<script setup>
import { computed, reactive } from 'vue'
import router from '../../../router';
import { useIssuesStore } from '../../../stores/issuesStore';
import { SaveOutlined } from '@ant-design/icons-vue';
import local from '../../../util/local-util';

const issuesStore = useIssuesStore();
const state = reactive({
  currentPath: computed(() => router.currentRoute.value.path),
  slug: computed(() => router.currentRoute.value.params?.slug),
  newCase: computed(() => local.get('newCase')),
  pathAfter: computed(() => 
    formatPathAfter(router.currentRoute.value.path.split('/').slice(-2))
  ),
  title: computed(() => issuesStore.currentIssue?.title),
});


function formatPathAfter(pathSegments){
  let pathAfter = '';
  pathSegments.forEach(element => {  
    if(element !== state.slug){
      pathAfter += element.replace(/-/g, ' ').replace(/\b\w/g, l => l.toUpperCase())
      pathAfter += ' / '
    }
  })
  return pathAfter.slice(0, -2);
}
</script>

<style scoped lang="scss">
@import "../../../scss/colors.scss";

.subheader {
  width: 100%;
  height: auto;
  background: #fff;
  margin-bottom: 25px;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &_item {
    text-align: right;

    &_btn {
      @media screen and (max-width: 576px) {
        width: 100%;
        margin-top: 20px;
      }
    }
  }
}
</style>