<script setup>
const props = defineProps({
  title: String,
})
</script>

<template>
  <a-card style="border-radius: 0; border: 0;">
    <template #title>
      <span>{{ props.title }}</span>
    </template>

    <template #default>
      <a-space direction="vertical">
        <a-typography-text class="response response_green">
          Positive/No Longer Concern response
        </a-typography-text>
        <a-typography-text class="response response_orange">
          Neutral response
        </a-typography-text>
        <a-typography-text class="response response_red">
          Negative/Concern response
        </a-typography-text>
        <a-typography-text class="response response_gray">
          Custom response
        </a-typography-text>
      </a-space>
    </template>
  </a-card>
</template>

<style lang="scss" scoped>
.response {
  padding: 5px 6px;

  &_green {
    border-left: 8px solid #54C860;
  }

  &_gray {
    border-left: 8px solid gray;
  }

  &_orange {
    border-left: 8px solid #F88804;
  }

  &_red {
    border-left: 8px solid red;
  }
}
</style>
