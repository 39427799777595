<template>
  <header class="header">
    <div class="left-menu">
      <nav @click="toggleMainMenu" class="menu-icon blue-color">
        <MenuOutlined style="width: 24px; font-size: 24px; padding-top: 8px;" />
        <!-- <CloseOutlined v-if="!state.collapsed" v-else style="width: 24px; font-size: 24px; padding-top: 8px;" /> -->
      </nav>
      <a-menu v-if="isMenuOpen" :selectedKeys="selectedKeys" class="main-menu" style="width: 100%" mode="inline">

        <a-menu-item v-if="isMobileScreen" class="menu-item-custom menu-item-logo" key="logo" @click="toHome">
          <div class="logo-mobile">
            <img alt="Patient Fairness Logo" src="/images/PF-logo-plain-2023-05-23.svg" class="logo-mobile-icon" />
            <label for="logo-mobile" style="cursor: pointer;" class="logo-mobile-label">
              <span class="logo-color-1">Patient&nbsp;</span>
              <span class="logo-color-2">Fairness</span>
            </label>
          </div>
        </a-menu-item>

        <a-menu-item class="menu-item-custom" key="main-menu-1" @click="toHome">
          <HomeOutlined class="blue-color" />
          <span class="blue-color">Dashboard</span>
        </a-menu-item>
        <a-divider style="margin: 0px; padding: 0px;" />
        <a-menu-item class="menu-item-custom" key="main-menu-2">
          <a href="https://guide.patientfairness.com/hc/en-us" target="_blank">
            <span class="blue-color">Guide</span>
          </a>
        </a-menu-item>

        <!-- <a-sub-menu class="sub-menu-custom" key="sub5">
          <template #title>
            <SettingOutlined />
            <span>Navigation Five</span>
          </template>
          <a-menu-item key="17">Option 17</a-menu-item>
          <a-menu-item key="18">Option 18</a-menu-item>
          <a-menu-item key="19">Option 19</a-menu-item>
          <a-menu-item key="20">Option 20</a-menu-item>
          <a-sub-menu key="sub6">
            <template #title>Submenu</template>
            <a-menu-item key="21">Option 21</a-menu-item>
            <a-menu-item key="22">Option 22</a-menu-item>
          </a-sub-menu>
        </a-sub-menu> -->
      </a-menu>
    </div>

    <div  @click="toHome">
      <a class="logo">
        <img alt="Patient Fairness Logo" src="/images/PF-logo-plain-2023-05-23.svg" class="logo-icon" />
        <label for="logo" style="cursor: pointer;" class="logo-label">
          <span class="logo-color-1">Patient&nbsp;</span>
          <span class="logo-color-2">Fairness</span>
        </label>
      </a>
    </div>

    <div class="right-menu">
      <nav class="help-menu-icon blue-color" @click="toggleHelpMenu">
        <QuestionCircleOutlined style="width: 24px; font-size: 24px; padding-top: 8px;" />
      </nav>
      <a-menu v-if="isHelpMenuOpen" :selectedKeys="selectedKeys" class="help-menu" style="width: 100%; padding-left: 0;" mode="inline">
        <a-menu-item class="menu-element"  key="help-menu-1" @click="openChat()">
          <QuestionCircleOutlined class="blue-color" />
          <span class="blue-color" >Need Help?</span>
        </a-menu-item>

        <a-divider style="margin: 0px; padding: 0px;" />

        <a-menu-item class="menu-element" key="help-menu-2">
          <a href="https://guide.patientfairness.com/hc/en-us" class="blue-color" target="_blank">
            <span class="blue-color">Guide</span>
          </a>
        </a-menu-item>

        <!-- <a-menu-item class="menu-element" key="help-menu-3" @click="contactUsForm()">
          <a>
            <span class="blue-color">Contact Us</span>
          </a>
        </a-menu-item> -->
      </a-menu>

      <nav class="user-menu-icon blue-color" @click="toggleUserMenu">
        <UserOutlined style="width: 24px; font-size: 24px; padding-top: 8px;" />
      </nav>
      <a-menu v-if="isUserMenuOpen" :selectedKeys="selectedKeys" class="user-menu" style="width: 100%" mode="inline">
        <a-menu-item key="user-menu-1" @click="logout" >
          <button class="nav-link blue-color" >Log Out</button>
        </a-menu-item>
      </a-menu>
    </div>




  </header>
</template>

<script setup>
import { onMounted, onBeforeUnmount, ref } from 'vue';
import { BellOutlined, CloseOutlined, HomeOutlined, MenuOutlined, QuestionCircleOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons-vue';
import { useMsal } from "../util/composition-api/useMsal.js";
import { msalConfig } from "../util/plugins/authConfig.js";
import local from "../util/local-util.js";
import { authStore } from "../stores/authentication.js";
import router from "../router/index.js";
import 'bootstrap'

// const state = reactive({
  // selectedKeys: ['1'],
  // openKeys: ['sub1'],
  // preOpenKeys: ['sub1'],
// });

const selectedKeys = ref([]);
// const helpOpenKeys = ref([]);
// const helpSelectedKeys = ref([]);

// const handleMenuClick = () => {
//       selectedKeys.value = []; // Clear the selected keys
// };

const isMenuOpen = ref(false);
const isHelpMenuOpen = ref(false);
const isUserMenuOpen = ref(false);
const isMobileScreen = ref(false);

function updateMenuState() {
  const width = window.innerWidth;
  if (width > 1399) {
    isMenuOpen.value = true;
    isHelpMenuOpen.value = true;
  } else if (width <= 380) {
    isMobileScreen.value = true;
  }
}

onMounted(() => {
  window.addEventListener('resize', updateMenuState);
  updateMenuState(); // Set initial state on mount
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateMenuState);
});

function toggleMainMenu() {
  isMenuOpen.value = !isMenuOpen.value;
}
function toggleHelpMenu() {
  isHelpMenuOpen.value = !isHelpMenuOpen.value;
  if (isHelpMenuOpen.value)
    isUserMenuOpen.value = false;
}
function toggleUserMenu() {
  isUserMenuOpen.value = !isUserMenuOpen.value;
  if (isUserMenuOpen.value)
    isHelpMenuOpen.value = false;
}

function openChat() {
  zE('messenger', 'open');
}

function contactUsForm() {
  // zE('messenger', 'open'); // TODO: add logic to show contact us form
  // window.zESettings = {
  //   webWidget: {
  //     contactOptions: {
  //       enabled: true,
  //       contactButton: { '*': 'Get in touch' },
  //       contactFormLabel: { '*': 'Leave us a message' }
  //     }
  //   }
  // };
  // zE('messenger', 'open');
}


function toHome() {
  router.push('/');
  // const homeUrl = import.meta.env.VITE_AUTH_REDIRECT_URL;
}

const { instance } = useMsal();

const logout = () => {
  const accounts = instance.getAllAccounts();

  accounts.forEach(async (account) => {
    const currentAccount = instance.getAccountByHomeId(account.homeAccountId);
    await instance.logoutRedirect({
      account: currentAccount,
      postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri,
      onRedirectNavigate: () => {
        const store = authStore();

        store.$patch({
          token: '',
          user: {
            id: '',
            person_id: '',
            email: ''
          },
          issue: {
            id: '',
            title: ''
          }
        })

        local.remove('newCase');
        local.remove('issueId');
        local.remove('id');

        return true;
      }
    });
  })

}
</script>

<style lang="scss" scoped>
@import "../scss/headerNew.scss";
</style>