<script lang="ts" setup>
import { reactive } from 'vue'
import router from '@/router'

const state = reactive({
  slug: router.currentRoute.value.params?.slug,
})
</script>

<template>
  <a-card hoverable class="card">
    <a-result title="Progress is empty">
      <template #extra>
        <a-button type="primary" @click="router.push({ name: 'issues.whoAreYou', params: { slug: state.slug } })">
          Start completing the case
        </a-button>
      </template>
    </a-result>
  </a-card>
</template>
