import api from "../api.js";
import ServiceResponse from "./ServiceResponse.js";

const PeopleService = {
    find: async (id) => {
        try {
            const { data } = await api({
                url: `/api/people/${id}`,
                method: 'GET'
            });
            return new ServiceResponse(true, data.data, null, null);
        }
        catch (e) {
            console.error("Failed to get current issue state: ", e);
            return new ServiceResponse(false, null, e.message, e.errors);
        }
    }
}

export default PeopleService;