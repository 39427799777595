export const responseRoutes = [
    {
      path: 'response',
      name: 'issues.response',
      component: () => import('../pages/ResponseLanding.vue'),
      meta: {
        requiresAuth: true,
        layout: 'IssueLayout',
      },
    },
    {
      path: 'response/evaluate',
      redirect: { name: 'issues.response' },
    },
    {
      path: 'response/evaluate/new',
      name: 'issues.responseEvaluateNew',
      component: () => import('../pages/ResponseEvaluate.vue'),
      meta: {
        requiresAuth: true,
        layout: 'IssueLayout',
      },
    },
    {
      path: 'response/evaluate/:id',
      name: 'issues.responseEvaluate',
      component: () => import('../pages/ResponseEvaluate.vue'),
      meta: {
        requiresAuth: true,
        layout: 'IssueLayout',
      },
    },
    {
      path: 'response/evaluate/:id/bill',
      name: 'issues.responseEvaluateBill',
      component: () => import('../pages/ResponseBill.vue'),
      meta: {
        requiresAuth: true,
        layout: 'IssueLayout',
      },
    },
    {
      path: 'response/evaluate/:id/bill/changes',
      name: 'issues.responseEvaluateBillChanges',
      component: () => import('../pages/ResponseChanges.vue'),
      meta: {
        requiresAuth: true,
        layout: 'IssueLayout',
      },
    },
    // {
    //   path: 'response/evaluate/:id/type',
    //   name: 'issues.responseEvaluateType',
    //   component: () => import('../pages/ResponseType.vue'),
    //   meta: {
    //   requiresAuth: true,
    //     layout: 'IssueLayout',
    //   },
    // },
    {
      path: 'response/evaluate/:id/insurance',
      name: 'issues.responseEvaluateInsurance',
      component: () => import('../pages/ResponseInsurance.vue'),
      meta: {
        requiresAuth: true,
        layout: 'IssueLayout',
      },
    },
    {
      path: 'response/evaluate/:id/gfe',
      name: 'issues.responseEvaluateGFE',
      component: () => import('../pages/ResponseGFE.vue'),
      meta: {
        requiresAuth: true,
        layout: 'IssueLayout',
      },
    },
    {
      path: 'response/evaluate/:id/state-specific',
      name: 'issues.responseEvaluateStateSpecific',
      component: () => import('../pages/ResponseStateSpecific.vue'),
      meta: {
        requiresAuth: true,
        layout: 'IssueLayout',
      },
    },
    {
      path: 'response/evaluate/:id/general',
      name: 'issues.responseEvaluateGeneral',
      component: () => import('../pages/ResponseGeneral.vue'),
      meta: {
        requiresAuth: true,
        layout: 'IssueLayout',
      },
    },
    {
      path: 'response/evaluate/:id/point-by-point',
      name: 'issues.responseEvaluatePbP',
      component: () => import('../pages/ResponsePointByPoint.vue'),
      meta: {
        requiresAuth: true,
        layout: 'IssueLayout',
      },
    },
    {
      path: 'response/evaluate/:id/summary',
      name: 'issues.responseEvaluateSummary',
      component: () => import('../pages/ResponseSummary.vue'),
      meta: {
        requiresAuth: true,
        layout: 'IssueLayout',
      },
    },
    {
      path: 'response/evaluate/:id/position',
      name: 'issues.responseEvaluatePosition',
      component: () => import('../pages/ResponsePosition.vue'),
      meta: {
        requiresAuth: true,
        layout: 'IssueLayout',
      },
    },
    {
      path: 'response/evaluate/:id/price-review',
      name: 'issues.responseEvaluatePriceReview',
      component: () => import('../pages/ResponsePriceReview.vue'),
      meta: {
        requiresAuth: true,
        layout: 'IssueLayout',
      },
    },
    {
      path: 'response/evaluate/:id/max-affordable',
      name: 'issues.responseEvaluateMaxAffordable',
      component: () => import('../pages/ResponseMaxAffordable.vue'),
      meta: {
        requiresAuth: true,
        layout: 'IssueLayout',
      },
    },
    {
      path: 'response/evaluate/:id/max-settlement',
      name: 'issues.responseEvaluateMaxSettlement',
      component: () => import('../pages/ResponseMaxSettlement.vue'),
      meta: {
        requiresAuth: true,
        layout: 'IssueLayout',
      },
    },
    {
      path: 'response/evaluate/:id/received-proposal',
      name: 'issues.responseEvaluateReceivedProposal',
      component: () => import('../pages/ResponseReceiveProposal.vue'),
      meta: {
        requiresAuth: true,
        layout: 'IssueLayout',
      },
    },
    {
      path: 'response/evaluate/:id/create-proposal',
      name: 'issues.responseEvaluateCreateProposal',
      component: () => import('../pages/ResponseCreateProposal.vue'),
      meta: {
        requiresAuth: true,
        layout: 'IssueLayout',
      },
    },
    {
      path: 'response/evaluate/:id/offer-payments',
      name: 'issues.responseEvaluateOfferPayments',
      component: () => import('../pages/ResponseOfferPayments.vue'),
      meta: {
        requiresAuth: true,
        layout: 'IssueLayout',
      },
    },
    {
      path: 'response/evaluate/:id/your-payments',
      name: 'issues.responseEvaluateYourPayments',
      component: () => import('../pages/ResponseOfferPayments.vue'),
      meta: {
        requiresAuth: true,
        layout: 'IssueLayout',
      },
    },
    {
      path: 'response/evaluate/:id/next-steps',
      name: 'issues.responseEvaluateNextSteps',
      component: () => import('../pages/ResponseNextSteps.vue'),
      meta: {
        requiresAuth: true,
        layout: 'IssueLayout',
      },
    },
    {
      path: 'response/evaluate/:id/todo',
      name: 'issues.responseEvaluateTodo',
      component: () => import('../pages/ResponseTodo.vue'),
      meta: {
        requiresAuth: true,
        layout: 'IssueLayout',
      },
    },
    {
      path: 'response/evaluate/:id/authorize',
      name: 'issues.responseEvaluateAuthorize',
      component: () => import('../pages/ResponseAuthorize.vue'),
      meta: {
        requiresAuth: true,
        layout: 'IssueLayout',
      },
    },
]