<script lang="ts" setup>
import { reactive } from 'vue'
import FastTrackModal from '../modal/FastTrackModal.vue'
import router from '@/router'

const state = reactive({
  slug: router.currentRoute.value.params?.slug,
  modal: {
    fastTrack: false,
  },
})

function updateFastTrack(fastTrack: boolean) {
  state.modal.fastTrack = fastTrack
}
</script>

<template>
  <a-card style="border-radius: 0; border: 0">
    <template #title>
      <span>Tired of entering your data manually?</span>
    </template>

    <a-card class="fast-track-card" @click="state.modal.fastTrack = true">
      <a-typography-paragraph>We can help!</a-typography-paragraph>
      <a-typography-text>
        Switch to uploading documents instead of manually entering information.
      </a-typography-text>
    </a-card>

    <a-modal
      v-model:open="state.modal.fastTrack" title="Uploading Your Documents" :footer="null"
      :closable="false" :mask-closable="false" width="100%" style="max-width: 768px;"
    >
      <FastTrackModal :slug="state.slug" @update:fast-track="updateFastTrack" />
    </a-modal>
  </a-card>
</template>

<style lang="scss" scoped>
.fast-track-card {
  margin: -10px;
  display: flex;
  justify-content: center;
  border: 1px solid #d9d9d9;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);

  &:hover {
    cursor: pointer;
    border: 1px solid #005d9e90;
  }
}
</style>
