import type { RouteRecordRaw, Router } from 'vue-router'

const moduleRoute: RouteRecordRaw = {
  path: '/issues/:slug/',
  meta: { title: "Case #" },
  children: [
    {
      path: 'example',
      component: () => import('../pages/Example.vue'),
      meta: {
        layout: 'IssueLayout',
      },
    },
  ],
}

export default (router: Router) => {
  router.addRoute(moduleRoute)
}
