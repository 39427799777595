import api from "../api.js";
import ServiceResponse from "./ServiceResponse.js";

/**
 * ProvidersService
 */
const ProvidersService =  {

    find: async (id) => {
        try {
            const { data } = await api({
                url: `/api/providers/${id}`,
            });

            return new ServiceResponse(true, data.data, null, null);
        } catch (e) {
            console.error("ProvidersService.find: ", e);

            return new ServiceResponse(false, null, e.message, e.errors);
        }
    },
}

export default ProvidersService;