import {defineStore} from 'pinia';

export const authStore = defineStore('authId', {
  state: () => ({
    isLoginInProcess: false,
    token: '',
    user: {
      id: '',
      person_id: '',
      email: ''
    },
    issue: {
      id: '',
      title: ''
    }
    // ? issue_id
  })
});