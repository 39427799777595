import type { RouteRecordRaw, Router } from 'vue-router'

const moduleRoute: RouteRecordRaw = {
  path: '/issues/:slug/communication',
  meta: { title: 'Case #' },
  children: [
    {
      path: '',
      name: 'issues.communication',
      component: () => import('../pages/Communication.vue'),
      meta: {
        requiresAuth: true,
        layout: 'IssueLayout',
      },
    },
    {
      path: ':id',
      children: [
        {
          path: '',
          name: 'issues.newCommunication',
          component: () => import('../pages/NewCommunication.vue'),
          meta: {
            requiresAuth: true,
            layout: 'IssueLayout',
          },
        },
        {
          path: '',
          name: 'issues.editDocumentCommunication',
          component: () => import('../pages/DocumentCommunication.vue'),
          meta: {
            requiresAuth: true,
            layout: 'IssueLayout',
          },
        },
        {
          path: '',
          name: 'issues.editInitiateCommunication',
          component: () => import('../pages/InitiateCommunication.vue'),
          meta: {
            requiresAuth: true,
            layout: 'IssueLayout',
          },
        },
        {
          path: 'template',
          name: 'issues.initiateCommunicationTemplate',
          component: () => import('../pages/templates/InitiateCommunicationTemplate.vue'),
          meta: {
            layout: 'IssueLayout',
          }
        }
      ],
    },
    {
      path: 'document',
      children: [
        {
          path: '',
          name: 'issues.beforeDocumentCommunication',
          component: () => import('../pages/beforeStart/BeforeDocument.vue'),
          meta: {
            requiresAuth: true,
            layout: 'IssueLayout',
          },
        },
        {
          path: 'new',
          name: 'issues.documentCommunication',
          component: () => import('../pages/DocumentCommunication.vue'),
          meta: {
            requiresAuth: true,
            layout: 'IssueLayout',
          },
        },
      ],
      meta: {
        layout: 'IssueLayout',
      },
    },
    {
      path: 'initiate',
      children: [
        {
          path: '',
          name: 'issues.beforeInitiateCommunication',
          component: () => import('../pages/beforeStart/BeforeInitiate.vue'),
          meta: {
            requiresAuth: true,
            layout: 'IssueLayout',
          },
        },
        {
          path: 'new',
          name: 'issues.initiateCommunication',
          component: () => import('../pages/InitiateCommunication.vue'),
          meta: {
            requiresAuth: true,
            layout: 'IssueLayout',
          },
        },
      ],
      meta: {
        layout: 'IssueLayout',
      },
    },
    {
      path: 'debt',
      children: [
        {
          path: '',
          name: 'issues.beforeDebtCollections',
          component: () => import('../pages/beforeStart/BeforeDebtCollections.vue'),
          meta: {
            requiresAuth: true,
            layout: 'IssueLayout',
          },
        }
      ],
      meta: {
        layout: 'IssueLayout',
      },
    }
  ],
}

export default (router: Router) => {
  router.addRoute(moduleRoute)
}
