import api from '@/util/api.js'
import ServiceResponse from '@/util/services/ServiceResponse.js'

const AuthorizationService = {
  /**
   * Send a request to authorize LOD
   *
   * @param {number} issue_id - The ID of the issue
   * @return {Promise<ServiceResponse>} a Promise that resolves to a ServiceResponse
   */
  lod: async (issue_id: number): Promise<ServiceResponse> => {
    try {
      const { data: response } = await api({
        url: `/api/issues/${issue_id}/authorize`,
        method: 'POST',
      })
      return new ServiceResponse(true, response.data, response.message, null)
    }
    catch (e: any) {
      console.error('AuthorizationService.lod: ', e)
      return new ServiceResponse(false, null, e.response?.data?.message, e.errors)
    }
  },
}

export default AuthorizationService
