<script setup>
import { computed, reactive } from 'vue'
import { useIssuesStore } from '@/stores/issuesStore'
import router from '@/router'

const issuesStore = useIssuesStore()

const state = reactive({
  currentPath: computed(() => router.currentRoute.value.path),
  metaTitle: computed(() => router.currentRoute.value.meta?.breadcrumb),
  title: computed(() => issuesStore.currentIssue?.title),
})

function findTitle() {
  if (state.metaTitle)
    return state.metaTitle
  else if (state.title && (state.title !== '-' || state.title !== '- '))
    return state.title
  else
    return 'Loading...'
}

function findBreadcrumb() {
  const pathSegments = state.currentPath.split('/').filter(segment => segment !== '')

  const breadcrumbItems = [
    {
      label: 'Dashboard',
      link: '/dashboard',
    },
  ]

  let currentLink = '/issues'
  for (let i = 0; i < pathSegments.length; i++) {
    const segment = pathSegments[i]
    if (segment === 'issues')
      continue

    currentLink += `/${segment}`

    let label = i === 1 ? `Case #${segment}` : segment.replace(/-/g, ' ')

    // Custom handling for specific labels
    if (label === 'fast track')
      label = 'Document Extract'


    breadcrumbItems.push({
      label,
      link: i === pathSegments.length - 1 ? null : currentLink,
    })
  }

  return breadcrumbItems
}
</script>

<template>
  <a-page-header class="subheader">
    <template #title>
      <a-space :style="{ marginTop: '15px' }">
        <h4 class="subheader_title">{{ findTitle() }}</h4>
      </a-space>
    </template>
    <template #breadcrumb>
      <a-breadcrumb>
        <a-breadcrumb-item v-for="(item, index) in findBreadcrumb()" :key="index">
          <router-link v-if="item.link" class="subheader_link" :to="item.link">
            {{ item.label.charAt(0).toUpperCase() + item.label.slice(1) }}
          </router-link>
          <span v-else>{{ item.label.charAt(0).toUpperCase() + item.label.slice(1) }}</span>
        </a-breadcrumb-item>
      </a-breadcrumb>
    </template>
  </a-page-header>
</template>

<style lang="scss" scoped>
@import "@/scss/colors.scss";

.subheader {
  width: 100%;
  height: auto;
  background: #fff;
  margin-bottom: 25px;

  &_title {
    text-transform: uppercase;
  }

  &_link {
    text-decoration: none;
  }
}
</style>
