<script setup>
import Header from '@/components/HeaderNew.vue'

const appVersion = import.meta.env.VITE_APP_VERSION
</script>

<template>
  <a-config-provider :theme="{ token: { colorPrimary: '#005e9e', orange: '#f59f00' } }">
    <section>
      <div class="container-nav">
        <div class="blur-background"></div>
          <Header />
      </div>
      <div class="bg-dashboard">
        <div class="container-pf margin-xs">
          <slot />
        </div>
      </div>
      <div class="footer bg-white">
        <label>v{{ appVersion }}</label>
      </div>
    </section>
  </a-config-provider>
</template>

<style scoped>
section {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.bg-dashboard {
  flex-grow: 1;
  background: #e3e3e3;
  padding: 25px 0 65px 0;
}

.footer {
  z-index: 99;
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  text-align: center;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.15);
}

.container-nav {
  background: white;
  color: black;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.container-pf {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
    /* margin-right: 148px; */
}

@media (max-width: 575.98px) {
  .margin-xs {
    padding: 0 5px;
  }
}

@media (min-width: 576px) {
  .container-pf {
    width: 540px;
  }
}

@media (min-width: 768px) {
  .container-pf {
    width: 720px;
  }
}

@media (min-width: 992px) {
  .container-pf {
    width: 960px;
  }
}

@media (min-width: 1200px) {
  .container-pf {
    width: 1100px;
  }
}
</style>
