import { msalInstance } from "../util/plugins/authConfig.js";
import local from "../util/local-util.js";
import {EventType} from "@azure/msal-browser";
import {authStore} from "../stores/authentication.js";
import AuthService from "../util/services/authentification.js";

export function registerGuard(router) {
  router.beforeEach(async(to, from) => {

    if (to.meta.requiresAuth || to.name === 'loading') {
      const isAuthenticated = await AuthService.isAuthenticated();

      if (!isAuthenticated) {
        if (to.meta.requiresAuth) {
          local.set('urlTo', to.fullPath);
          return '/'
        } else {
          return true;
        }
      }
    }

    const isNew = AuthService.isNew();
    if (to.name === 'loading') {
      if(isNew)
        return '/screening'
      else
        return '/dashboard'
    }

    return true;
  });
}

msalInstance.addEventCallback(async(event) => {
  // set active account after redirect
  const store = authStore();

  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    try {
      const account = event.payload.account;
      msalInstance.setActiveAccount(account);
      store.$patch(await AuthService.login())
    } catch (error) {
      throw error;
    }
  }
}, error => {
  console.log('error', error);
});
