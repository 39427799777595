<script lang="ts" setup>
import { computed, onMounted, reactive } from 'vue'
import Header from '@/components/HeaderNew.vue'
import ProgressCard from '@/modules/core/components/progress/ProgressCard.vue'
import CompactProgressCard from '@/modules/core/components/progress/CompactProgressCard.vue'
import { useIssuesStore } from '@/stores/issuesStore'
import SubHeader from '@/view/issues/steps/SubHeader.vue'
import router from '@/router/index.js'

const issuesStore = useIssuesStore()

const state = reactive({
  slug: router.currentRoute.value.params?.slug,
  currentIssue: computed(() => issuesStore.currentIssue),
  screenWidth: computed(() => window.innerWidth),
  loading: false,
})

// onMounted(async () => {
//   await issuesStore.findBySlug(state.slug)
// })

const appVersion = import.meta.env.VITE_APP_VERSION
</script>

<template>
  <a-config-provider :theme="{ token: { borderRadius: 2, colorPrimary: '#005e9e', orange: '#f59f00' } }">
    <section>
      <div class="container-nav">
          <Header />
      </div>
      <div class="bg-dashboard">
        <div class="container-pf margin-xs">
          <SubHeader />
          <a-row :gutter="[15, 15]" class="mobile_row">
            <a-col :span="24" :md="{ span: 24 }" :lg="{ span: 16 }" :xl="{ span: 16 }" class="mobile_col">
              <a-row :gutter="[15, 15]" class="mobile_row">
                <a-col :span="24" class="mobile_col">
                  <router-view v-slot="{ Component }">
                    <transition name="step" mode="out-in">
                      <component :is="Component" />
                    </transition>
                  </router-view>
                </a-col>
              </a-row>
            </a-col>
            <a-col :span="24" :md="{ span: 24 }" :lg="{ span: 8 }" :xl="{ span: 8 }" class="mobile_col">
              <a-row :gutter="[15, 15]" class="mobile_row">
                <a-col
                  v-if="state.screenWidth >= 992" :span="24" :md="{ span: 12 }" :lg="{ span: 24 }"
                  class="mobile_col"
                >
                  <ProgressCard :loading="state.loading" :data="state.currentIssue" />
                </a-col>
                <a-col v-if="state.screenWidth < 992" :span="24" :md="{ span: 12 }" :lg="{ span: 24 }" class="mobile_col">
                  <CompactProgressCard :loading="state.loading" :data="state.currentIssue" />
                </a-col>
              </a-row>
            </a-col>
          </a-row>
        </div>
      </div>
      <div class="footer bg-white">
        <label>v{{ appVersion }}</label>
      </div>
    </section>
  </a-config-provider>
</template>

<style lang="scss" scoped>
@import "@/scss/issue-layout.scss";
</style>
