import api from '../api.js'
import ServiceResponse from './ServiceResponse.js'

const LODSettingsService = {
  /**
   * Finds lod settings for a given issue.
   *
   * @param {int} id
   * @returns {Promise<ServiceResponse>}
   */
  find: async (id) => {
    try {
      const { data } = await api({
        url: `/api/issues/${id}/lod-settings`,
      })

      return new ServiceResponse(true, data.data, null, null)
    }
    catch (e) {
      console.error('LODSettingsService.find: ', e)
      return new ServiceResponse(false, null, e.message, e.errors)
    }
  },

  /**
   * Saves lod for a given issue.
   *
   * @param {string} issueId
   * @param {object} formData
   * @return {ServiceResponse}
   */
  save: async (issueId, formData) => {
    try {
      const { data } = await api({
        url: `/api/issues/${issueId}/lod`,
        method: 'POST',
        formData,
      })
      return new ServiceResponse(true, data.data, null, null)
    }
    catch (e) {
      console.error('LODSettingsService.save: ', e)
      return new ServiceResponse(false, null, e.message, e.errors)
    }
  },

  /**
   * Retrieves a preview of a letter of dispute.
   *
   * @param {number} issueId
   * @returns {object|Error} data
   */

  preview: async (issueId) => {
    try {
      const { data } = await api({
        url: `/api/letters/lod/preview?issue_id=${issueId}`,
        method: 'GET',
      })

      return new ServiceResponse(true, data.data, null, null)
    }
    catch (e) {
      console.error('LODSettingsService.preview: ', e)
      return new ServiceResponse(false, null, e.message, e.errors)
    }
  },

  options: [
    {
      label: 'Do not recognize the provider.',
      value: 'provider_not_recognized',
    },
    {
      label: 'Do not recognize the services billed.',
      value: 'services_not_received',
    },
    {
      label: 'Clarify and document that the services and dates on the Medical Bill are correct.',
      value: 'services_dates_incorrect',
    },
    {
      label: 'Request documentation that patient consented to services.',
      value: 'treatment_not_authorized',
    },

    {
      label: 'Not aware that GFE was issued. Ask the Provider if it issued a Good Faith Estimate.',
      value: 'gfe_not_received_not_requested',
    },
    {
      label: 'The Provider did not issue a Good Faith Estimate, despite that the Patient requested one.',
      value: 'gfe_requested_not_received',
    },
    {
      label: 'The Provider’s disclosure on services and prices does not meet the requirements for a Good Faith Estimate.',
      value: 'gfe_incomplete',
    },
    {
      label: 'Why do the prices on the Medical Bill exceed the prices on the Good Faith Estimate.',
      value: 'bill_greater_than_gfe',
    },
    {
      label: 'The prices on the Medical Bill exceed the prices on the Good Faith Estimate by $400 or more. This is a potential No Surprises Act violation.',
      value: 'bill_greater_than_gfe_400',
    },
    {
      label: 'Ask the Provider to confirm that the prices on the Good Faith Estimate match the Medical Bill charges.',
      value: 'bill_gfe_cannot_discern_match',
    },
    {
      label: 'The services on the Good Faith Estimate do not match those on the Medical Bill.',
      value: 'bill_gfe_services_mismatch',
    },
    {
      label: 'You are unsure if the services on the Good Faith Estimate match the services on Medical Bill.',
      value: 'bill_gfe_services_not_sure_match',
    },
    {
      label: 'The Good Faith Estimate was not issued in the timeframes required under the No Surprises Act.',
      value: 'gfe_received_later_than_1_day',
    },
    {
      label: 'The Good Faith Estimate was not issued in the timeframes required under the No Surprises Act.',
      value: 'gfe_received_later_than_3_days',
    },
    // 1.4
    {
      label: 'The Medical Bill for emergency care exceeds what my insurance says I owe.',
      value: 'balance_bill_er_insured',
    },
    {
      label: 'I need to evaluate the Medical Bill’s prices for emergency ground ambulance services. ',
      value: 'balance_bill_gr_amb_insured',
    },
    {
      label: 'I need to evaluate the Medical Bill’s prices for emergency care.',
      value: 'balance_bill_er_not_insured',
    },
    {
      label: 'I went to a facility participating in my insurance network, yet the provider’s Medical Bill exceeds what I should owe under my in-network insurance benefits.',
      value: 'balance_bill_facility_par',
    },
    {
      label: 'The Provider did not inform the patient that it was not participating in my insurance network.',
      value: 'balance_bill_provider_not_informed',
    },
    {
      label: 'The Provider is participating in my insurance network, yet the Medical Bill seeks payment from me above what my insurance says I owe. ',
      value: 'balance_bill_par_provider',
    },
    // 1.5.1
    {
      label: 'The Medical Bill was not issued in a timely manner.',
      value: 'timeliness_to_issue_bill',
    },
    // 1.6
    {
      label: 'Despite the Patient’s request, Provider never disclosed to the Patient the prices it expected to collect for services related to the Medical Bill.',
      value: 'no_disclosure_requested',
    },
    {
      label: 'Provider never disclosed to the Patient the prices it expected to collect for services related to the Medical Bill.',
      value: 'no_disclosure_not_requested',
    },
    {
      label: 'Provider’s advance disclosure of services to be rendered underestimated the quantity or nature of the services compared to the Medical Bill.',
      value: 'disclosure_underestimate_services'
    },
    {
      label: 'Provider’s advance disclosure to the Patient underestimated the prices compared to the Medical Bill.',
      value: 'disclosure_underestimate_prices',
    },
    // 2.0
    {
      label: 'Medical Bill charges and Agreement charges do not match or may not match.',
      value: 'bill_price_and_agreement_mismatch',
    },
    {
      label: 'My payments are not reflected on the Medical Bill.',
      value: 'patient_payments_not_shown',
    },
    {
      label: 'Insurance payments are not reflected on the Medical Bill.',
      value: 'insurer_payments_not_shown',
    },
    // 3.0
    {
      label: 'Ask if a claim was filed with insurance.',
      value: 'insurance_claim',
    },
    {
      label: 'Request that the provider files a claim with your insurance plan',
      value: 'request_to_file_insurance_claim',
    },
    {
      label: 'Did not authorize Medical Provider to accept funds directly from insurance company.',
      value: 'did_not_agree_to_assign_benefits',
    },
    {
      label: 'Cite California billing restrictions for non-participating providers practicing at participating facilities.',
      value: 'ca_out_of_network_costs',
    },
    {
      label: 'Cite Texas requirements for hospital bills.',
      value: 'tx_out_of_network_costs',
    },
    {
      label: 'Cite California restrictions on credit reporting on hospital bills.',
      value: 'ca_hospipal_credit_reporting',
    },
    // 8.0
    {
      "label": "Cite California law regarding patient protections from ground ambulance balance billing.",
      "value": "ca_surprise_billing_gr_amb"
    },
    {
      "label": "Cite California law regarding patient protections from balance billing when receiving care at a participating (insurance network) facility.",
      "value": "ca_balance_billing_no_par"
    },
    {
      "label": "Cite California law regarding required discounts for qualifying low income persons.",
      "value": "ca_qualifying_hospital_price"
    },
    {
      "label": "Cite Colorado law regarding prohibitions and restrictions on billing facility fees.",
      "value": "co_facility_fee"
    },
    {
      "label": "Cite Colorado law regarding required discounts for qualifying low income persons.",
      "value": "co_qualifying_hospital_price"
    },
    {
      "label": "Cite Colorado law regarding patient protections from ground ambulance balance billing.",
      "value": "co_surprise_billing_gr_amb"
    },
    {
      "label": "Cite Connecticut law regarding prohibitions and restrictions on billing facility fees.",
      "value": "ct_facility_fee"
    },
    {
      "label": "Cite Connecticut law regarding patient protections from balance billing of a facility fee for emergency services and when receiving care at a participating (insurance network) facility.",
      "value": "ct_surprise_billing"
    },
    {
      "label": "Cite Connecticut law regarding required discounts for qualifying low income persons.",
      "value": "ct_qualifying_hospital_price"
    },
    {
      "label": "Cite Georgia law regarding patient protections from balance billing when receiving care at a participating (insurance network) facility.",
      "value": "ga_balance_billing_no_par"
    },
    {
      "label": "Cite Idaho law regarding time limits for providers to issue medical bills.",
      "value": "id_bill_time_limit_non_insured"
    },
    {
      "label": "Cite Idaho law regarding restrictions on medical debt collection.",
      "value": "id_extraordinary_coll_non_insured"
    },
    {
      "label": "Cite Idaho law regarding restrictions on medical debt collection.",
      "value": "id_extraordinary_coll_insured"
    },
    {
      "label": "Cite Illinois law regarding requirements of providers to discuss the accuracy of a bill and to respond timely to patient billing inquiries.",
      "value": "il_provider_responsiveness"
    },
    {
      "label": "Cite Illinois law regarding required discounts for qualifying low income persons.",
      "value": "il_qualifying_hospital_price"
    },
    {
      "label": "Cite Indiana law regarding prohibitions and restrictions on billing facility fees.",
      "value": "in_facility_fee"
    },
    {
      "label": "Cite Maryland law regarding prohibitions and restrictions on billing facility fees.",
      "value": "md_facility_fee"
    },
    {
      "label": "Cite Maryland law regarding required discounts for qualifying low income persons.",
      "value": "md_qualifying_hospital_price"
    },
    {
      "label": "Cite Massachusetts law regarding prohibitions and restrictions on billing facility fees.",
      "value": "ma_facility_fee"
    },
    {
      "label": "Cite Massachusetts law regarding patient protections from balance billing for emergency services.",
      "value": "ma_surprise_billing_non_par"
    },
    {
      "label": "Cite Minnesota law regarding prohibitions and restrictions on billing facility fees.",
      "value": "mn_facility_fee"
    },
    {
      "label": "Cite North Carolina law requiring providers to issue itemized bills.",
      "value": "nc_itemized_bill"
    },
    {
      "label": "Cite New Jersey law regarding prohibitions on balance billing for non-participating professional or ancillary services rendered at a participating facility.",
      "value": "nj_balance_billing_non_par"
    },
    {
      "label": "Cite New Jersey law regarding patient protections from balance billing for emergency services.",
      "value": "nj_balance_billing_non_par_er"
    },
    {
      "label": "Cite New Jersey law regarding requirements for a provider to disclose to a patient if it is not participating in a patient’s insurance network.",
      "value": "nj_surprise_billing_non_par"
    },
    {
      "label": "Cite New Jersey law regarding required discounts for qualifying low income persons.",
      "value": "nj_qualifying_hospital_price"
    },
    {
      "label": "Cite New Mexico law regarding required discounts for qualifying low income persons.",
      "value": "nm_qualifying_hospital_price"
    },
    {
      "label": "Cite New York law regarding prohibitions and restrictions on billing facility fees.",
      "value": "ny_facility_fee"
    },
    {
      "label": "Cite New York law regarding required discounts for qualifying low income persons.",
      "value": "ny_qualifying_hospital_price"
    },
    {
      "label": "Cite Ohio law regarding prohibitions and restrictions on billing facility fees.",
      "value": "oh_facility_fee"
    },
    {
      "label": "Cite Oklahoma law regarding required discounts for qualifying low income persons.",
      "value": "ok_qualifying_hospital_price"
    },
    {
      "label": "Cite Oregon law regarding required discounts for qualifying low income persons.",
      "value": "or_qualifying_hospital_price"
    },
    {
      "label": "Cite Rhode Island law regarding required discounts for qualifying low income persons.",
      "value": "ri_qualifying_hospital_price"
    },
    {
      "label": "Cite Tennessee law regarding required discounts for uninsured persons.",
      "value": "tn_uninsured_facility_price"
    },
    {
      "label": "Cite Texas law requiring providers to issue itemized bills.",
      "value": "tx_itemized_bill"
    },
    {
      "label": "Cite Texas law regarding time limits for provider to issue bills.",
      "value": "tx_timely_bill"
    },
    {
      "label": "Cite Texas law regarding patient protections from ground ambulance balance billing.",
      "value": "tx_surprise_billing_gr_amb"
    },
    {
      "label": "Cite Vermont law requiring providers to issue itemized bills.",
      "value": "vt_itemized_bill"
    },
    {
      "label": "Cite Vermont law regarding required discounts for qualifying low income persons.",
      "value": "vt_qualifying_hospital_price"
    },
    {
      "label": "Cite Virginia law requiring providers to issue itemized bills.",
      "value": "va_itemized_bill"
    },
    {
      "label": "Cite Washington law regarding required discounts for qualifying low income persons.",
      "value": "wa_qualifying_hospital_price"
    },
    // 9.0
    {
      label: 'Cite that Provider was noncompliant with the federal Hospital Price Transparency Rule for the Medical Bill’s date(s) of service. ',
      value: 'general_price_transparency',
    },
    {
      label: 'Cite that Provider was noncompliant with the federal Hospital Price Transparency Rule for the Medical Bill’s date(s) of service, and Arizona’s own requirement to comply with the federal Hospital Price Transparency Rule. ',
      value: 'az_price_transparency',
    },
    {
      label: 'Cite that Provider was noncompliant with the federal Hospital Price Transparency Rule for the Medical Bill’s date(s) of service, and Arkansas’s own requirement to comply with the federal Hospital Price Transparency Rule. ',
      value: 'ar_price_transparency',
    },
    {
      label: 'Cite that Provider was noncompliant with the federal Hospital Price Transparency Rule for the Medical Bill’s date(s) of service, and Colorado’s own requirement to comply with the federal Hospital Price Transparency Rule.  ',
      value: 'co_price_transparency',
    },
    {
      label: 'Cite that Provider was noncompliant with the federal Hospital Price Transparency Rule for the Medical Bill’s date(s) of service, and Texas’s own requirement to comply with the federal Hospital Price Transparency Rule. ',
      value: 'tx_price_transparency',
    },
    {
      label: 'Cite that Provider was noncompliant with the federal Hospital Price Transparency Rule for the Medical Bill’s date(s) of service, and Virginia’s own requirement to comply with the federal Hospital Price Transparency Rule. ',
      value: 'va_price_transparency',
    },
    {
      label: 'Request a new version of the medical bill that complies with insurance standards.',
      value: 'request_insurance_compliant_bill',
      tooltip: 'Cannot be deselected. Required content for Medical Bill Price Review.',
      disabled: true,
    },
  ],
  byValue: (value) => {
    return LODSettingsService.options.find(o => o.value === value)
  },
}

export default LODSettingsService
