import api from '../api.js'
import ServiceResponse from './ServiceResponse.js'

const DisputeService = {
  /**
   * Finds gfe record by ID.
   *
   * @param {number} id
   * @returns {Promise<ServiceResponse>}
   */
  findGFE: async (id) => {
    try {
      const { data } = await api({
        url: `/api/issues/${id}/gfe`,
      })

      return new ServiceResponse(true, data.data, null, null)
    }
    catch (e) {
      console.error('DisputeService.findGFE: ', e)
      return new ServiceResponse(false, null, e.message, e.errors)
    }
  },

  /**
   * Saves the provider and services for a given issue.
   *
   * @param {string} issueId
   * @param {object} formData
   * @return {ServiceResponse}
   */
  saveProviderAndServices: async (issueId, formData) => {
    try {
      const { data } = await api({
        url: `/api/issues/${issueId}/dispute/provider-and-services`,
        method: 'POST',
        formData,
      })
      return new ServiceResponse(true, data.data, null, null)
    }
    catch (e) {
      console.error('DisputeService.saveProviderAndServices: ', e)
      return new ServiceResponse(false, null, e.message, e.errors)
    }
  },

  /**
   * Saves the dispute details for a given issue.
   *
   * @param {string} issueId
   * @param {object} formData
   * @return {ServiceResponse}
   */
  saveDisputeDetails: async (issueId, formData) => {
    try {
      const { data } = await api({
        url: `/api/issues/${issueId}/dispute/payments-made`,
        method: 'POST',
        formData,
      })
      return new ServiceResponse(true, data.data, null, null)
    }
    catch (e) {
      console.error('DisputeService.saveDisputeDetails: ', e)
      return new ServiceResponse(false, null, e.message, e.errors)
    }
  },

  /**
   * Saves the gfe record for a given issue.
   *
   * @param {string} issueId
   * @param {object} formData
   * @return {ServiceResponse}
   */
  saveGFE: async (issueId, formData) => {
    try {
      const { data } = await api({
        url: `/api/issues/${issueId}/dispute/gfe`,
        method: 'POST',
        formData,
      })
      return new ServiceResponse(true, data.data, null, null)
    }
    catch (e) {
      console.error('DisputeService.saveGFE: ', e)
      return new ServiceResponse(false, null, e.message, e.errors)
    }
  },

  /**
   * Saves the gfe record for a given issue.
   *
   * @param {string} issueId
   * @param {object} formData
   * @return {ServiceResponse}
   */
  saveGFEDetails: async (issueId, formData) => {
    try {
      const { data } = await api({
        url: `/api/issues/${issueId}/dispute/gfe-details`,
        method: 'POST',
        formData,
      })
      return new ServiceResponse(true, data.data, null, null)
    }
    catch (e) {
      console.error('DisputeService.saveGFEDetails: ', e)
      return new ServiceResponse(false, null, e.message, e.errors)
    }
  },

  /**
   * Saves the Dispute Details Agreements
   *
   * @param {string} issueId
   * @param {object} formData
   * @return {ServiceResponse}
   */
  saveDisputeDetailsAgreements: async (issueId, formData) => {
    try {
      const { data, error } = await api({
        url: `/api/issues/${issueId}/dispute/agreements`,
        method: 'POST',
        formData,
      })
      return new ServiceResponse(true, data.data, null, null)
    }
    catch (e) {
      console.error('DisputeService.saveDisputeDetailsAgreements: ', e)
      return new ServiceResponse(false, null, e.message, e.errors)
    }
  },

  /**
   * Saves the Disclosure of Services and Prices
   * 
   * @param {string} issueId
   * @param {object} formData
   * @return {ServiceResponse}
   */
  saveDisclosure: async (issueId, formData) => {
    try {
      const { data } = await api({
        url: `/api/issues/${issueId}/dispute/disclosure`, // TODO: change this to the correct endpoint if changed
        method: 'POST',
        formData,
      })
      return new ServiceResponse(true, data.data, null, null)
    } catch (e) {
      console.error('DisputeService.saveDisclosure: ', e)
      return new ServiceResponse(false, null, e.message, e.errors)
    }
  },

  /**
   * Saves the State Specific details
   *
   * @param {number} issue_id
   * @param {object} body
   * @return {ServiceResponse}
   */
  saveStateSpecific: async (issue_id, body) => {
    try {
      const { data } = await api({
        url: `/api/issues/${issue_id}/dispute/state-level`,
        method: 'POST',
        body,
      })
      return new ServiceResponse(true, data.data, null, null)
    }
    catch (e) {
      console.error('DisputeService.saveStateSpecific: ', e)
      return new ServiceResponse(false, null, e.message, e.errors)
    }
  },
}

export default DisputeService
