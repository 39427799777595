<script setup lang="ts">
import { ref } from 'vue'
import { InfoCircleOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons-vue'
import { handleError } from '@/components/customer/notifications/issueNotifications'
import { useIssuesStore } from '@/stores/issuesStore'
import router from '@/router'

const props = defineProps({
  slug: {
    type: String,
    required: true,
  },
})

const emits = defineEmits(['update:fastTrack'])

const issuesStore = useIssuesStore()

const submitting = ref(false)
const spinner = ref(false)
const docsNeeded = ref(false)
const payload = ref({
  fast_track_status: '',
})

async function selectRoute(route: string) {
  submitting.value = true
  try {
    if (route === 'fast') {
      spinner.value = true
      payload.value.fast_track_status = 'started'
      await issuesStore.processFastTrack(payload.value)

      if (issuesStore.success)
        router.push({ name: 'issues.fastTrack', params: { slug: props.slug } })
    }
    else if (route === 'normal') {
      emits('update:fastTrack', false)
      payload.value.fast_track_status = 'skipped'
      await issuesStore.processFastTrack(payload.value)
    }
  }
  catch (error) {
    handleError(error)
  }

  spinner.value = false
  submitting.value = false
}
</script>

<template>
  <a-space direction="vertical" size="large">
    <a-typography-text>
      Save time. You share the Initial Documents with us, and we find the right information.
    </a-typography-text>

    <a-alert>
      <template #message>
        <div style="display: flex; align-items: center; justify-content: space-between">
          <a-typography-text strong>
            What Documents Do You Need?
          </a-typography-text>

          <span class="fast-track_alertbox" @click="docsNeeded = !docsNeeded">
            <PlusOutlined v-if="!docsNeeded" />
            <MinusOutlined v-else />
          </span>
        </div>

        <template v-if="docsNeeded">
          <a-typography-paragraph>
            Patient Fairness uses information from the following documents (the “Initial Documents”) related to your
            medical bill dispute to assess your case and what reasons you may have to dispute the bill.
          </a-typography-paragraph>

          <ol style="font-size: 14px;">
            <li>The <em>medical bill</em> you are disputing.</li>
            <li>The patient's <em>health insurance identification card</em> – front and back (if using insurance).</li>
            <li>
              The insurance plan's <em>Explanation of Benefits</em> (if using insurance) for the insurance claim
              associated with the medical bill (if using insurance).
            </li>
          </ol>
        </template>
      </template>
    </a-alert>

    <a-typography-text>
      Please gather the Initial Documents to upload copies to your case.
      <br>
      <a-typography-text type="secondary">
        Patient Fairness accepts the following file types: PNG, JPEG, TIFF, and PDF formats.
      </a-typography-text>
    </a-typography-text>

    <div style="margin-top: 5px; margin-bottom: -15px">
      <a-typography-paragraph>
        Patient Fairness will retrieve the relevant information from the uploaded Initial Documents and pre-populate
        answers for your questions in the Problem Medical Bill Assessment. <br> You will have the opportunity to review
        and revise these answers based on your own knowledge of the case.
      </a-typography-paragraph>
    </div>

    <a-button type="primary" size="large" block :loading="spinner" @click="selectRoute('fast')">
      Proceed to upload initial documents
    </a-button>

    <a-divider :plain="true" style="margin: -10px 0;">
      <a-typography-text type="secondary">
        or
      </a-typography-text>
    </a-divider>

    <a-typography-text>
      I don’t want to upload my documents. I will enter the information from the documents myself as I complete the
      Problem Medical Bill Assessment.
    </a-typography-text>
    <div style="display: flex; justify-content: center; align-items: center; gap: 8px; margin-bottom: 20px">
      <a-typography-link type="default" :disabled="submitting" @click="selectRoute('normal')">
        Continue to enter information manually
      </a-typography-link>
      <a-tooltip>
        <template #title>
          If you cannot, or prefer not to, upload documents for Patient Fairness to retrieve the relevant information,
          you may enter the information yourself while you complete the Problem Medical Bill Assessment. You will still
          need access to information from the medical bill and other documents to complete the Problem Medical Bill
          Assessment, but you won’t need to upload the documents.
        </template>
        <InfoCircleOutlined style="font-size: 16px;padding-bottom: 2px;" />
      </a-tooltip>
    </div>

    <!-- <a-collapse>
      <a-collapse-panel key="1" header="Additional Information">
        <a-typography-text strong>
          Can I upload Other Documents?
        </a-typography-text>
        <a-typography-paragraph>
          Depending on the nature of your dispute, customers will be prompted during the Problem
          Medical Bill Assessment to share additional documents related to your case. You can limit the documents you
          share right now to just the Initial Documents, and wait to upload other documents later, when asked.
        </a-typography-paragraph>
      </a-collapse-panel>
    </a-collapse> -->
  </a-space>
</template>

<style lang="scss" scoped>
.fast-track {
  &_alertbox {
    padding: 0 4px;
    border-radius: 2px;
    cursor: pointer;

    &:hover {
      background: #ceefff;
      color: #2d9bff;
    }
  }
}
</style>
