<script lang="ts" setup>
import { computed, reactive, watch } from 'vue'
import Icon, { CheckCircleOutlined, DoubleRightOutlined, DownOutlined, LockOutlined, MinusCircleFilled } from '@ant-design/icons-vue'
import { findCardTitle, findRouteByActivity, findRouteByStep, findTitle } from '../../utils/progress'
import type { Activity, ProgressData, TreeItem } from '../../types/progress'
import router from '@/router'

const props = defineProps(['data', 'loading'])

const state = reactive({
  slug: router.currentRoute.value.params?.slug,
  progress: computed(() => props.data?.progress?.stages ?? []),
  showCardBody: -1,
})

function mapActivities(activities: Activity[]): TreeItem[] {
  return activities
    .filter(activity => activity.status !== 'skipped')
    .map((activity, index) => {
      const children = activity.steps ? mapActivities(activity.steps) : []
      children.forEach((child) => {
        child.parameters = {
          ...child.parameters,
          ...activity.parameters,
        };
      })
      return {
        title: findTitle(activity.slug)?.title ?? activity.slug,
        key: `${activity.slug}-${index}`,
        parameters: activity.parameters,
        status: activity.status,
        type: activity.type,
        id: activity.id,
        children,
      }
    })
}

// map state.progress to treeData
const treeData = computed(() => {
  return state.progress.map((progress: ProgressData) => {
    const data = mapActivities(progress.activities)
    const expandedKeys = getExpandedKeys(data)
    return {
      progress,
      data,
      expandedKeys,
    }
  })
})

function getExpandedKeys(data: any[]): any[] {
  const expandedKeys: any[] = []

  data.forEach((item) => {
    if (item.status === 'started')
      expandedKeys.push(item.key)

    if (item.children && item.children.length > 0)
      expandedKeys.push(...getExpandedKeys(item.children))
  })

  return expandedKeys
}

function goToPage(title: string, id: number, status: string, parameters: any): void {
  if ((title !== 'Screening' && title !== 'Create a Case') || !state.slug) {
    if (status === 'started' || status === 'completed') {
      if (findRouteByActivity(title) && id)
        router.push({ name: findRouteByActivity(title), params: { slug: state.slug, id: Object.values(parameters)[0] } })
      else if (findRouteByStep(title))
        router.push({ name: findRouteByStep(title), params: { slug: state.slug, id: Object.values(parameters)[0] } })
    }
  }
}

function toggleCardBody(active: boolean, index: number): void {
  if (!active || state.showCardBody === index)
    state.showCardBody = state.showCardBody === index ? -1 : index
}
</script>

<template>
  <template v-for="(progressData, index) in treeData" :key="index">
    <a-card class="progress-card" style="margin-bottom: 15px; border-radius: 0; border: 0;" @click="toggleCardBody(progressData.progress.active, index)">
      <template #title>
        <span>{{ findCardTitle(progressData.progress.id)?.title ?? progressData.progress.id }}</span>
      </template>
      <template #extra>
        <Icon v-if="progressData.progress.active">
          <template #component>
            <img src="/icons/circle-orange.svg" alt="O" style="padding-right: 4px">
          </template>
        </Icon>
        <CheckCircleOutlined v-else :style="{ fontSize: '24px', color: '#54C860', paddingRight: '4px' }" />
      </template>

      <template v-if="progressData.progress.active || state.showCardBody === index" #default>
        <div @click.stop>
          <a-directory-tree
            :key="progressData.expandedKeys" :default-expanded-keys="progressData.expandedKeys" :tree-data="progressData.data"
            :selectable="false"
            :show-icon="false"
            block-node
          >
            <template #switcherIcon="{ switcherCls }">
              <DownOutlined :class="switcherCls" />
            </template>
            <template #title="{ title, status, id, children, parameters }">
              <a-row
                :wrap="false" :gutter="[12, 0]" align="middle"
                :style=" { marginLeft: !children.length ? '-28px' : '-4px' }"
                @click="!children.length && goToPage(title, id, status, parameters)"
              >
                <a-col v-if="!children.length" flex="none">
                  <Icon>
                    <template #component>
                      <img width="12" height="12" src="/icons/bullet.svg" alt="o" style="margin-top: 8px;">
                    </template>
                  </Icon>
                </a-col>
                <a-col flex="auto">
                  {{ title }}
                </a-col>
                <a-col flex="none">
                  <template v-if="status === 'completed'">
                    <CheckCircleOutlined :style="{ fontSize: '24px', color: '#54C860' }" />
                  </template>
                  <template v-else-if="status === 'started'">
                    <Icon>
                      <template #component>
                        <img src="/icons/circle-orange.svg" alt="O" style="margin-right: -2px">
                      </template>
                    </Icon>
                  </template>
                  <template v-else-if="status === 'skipped'">
                    <DoubleRightOutlined :style="{ fontSize: '24px', color: '#707070' }" />
                  </template>
                  <template v-else>
                    <LockOutlined :style="{ fontSize: '24px', color: '#707070' }" />
                  </template>
                </a-col>
              </a-row>
            </template>
          </a-directory-tree>
        </div>
      </template>
    </a-card>
  </template>
</template>
