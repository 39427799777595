<script setup lang="ts">
import { h } from 'vue'
import Icon, { CloseOutlined, DollarCircleOutlined, ZoomInOutlined } from '@ant-design/icons-vue'
import { useMetrics } from '../composables/useMetrics'

const {
  tableState,
  getUniqueValues,
  highlightCells,
} = useMetrics()
</script>

<template>
  <a-card id="overcharges" class="card" style="border-radius: 0; border: 0;">
    <template #title>
      <a-space>
        <DollarCircleOutlined :style="{ fontSize: '22px', color: '#FFC107' }" />
        <p class="ant-card-head-title" style="margin-bottom: 0;">
          Possible Overcharges
        </p>
      </a-space>
    </template>
    <div v-if="!tableState.loading && tableState.overcharges.length === 0">
      <a-empty description="No conflicts identified between sources in Case Metrics" />
    </div>
    <div v-else>
      <a-row
        v-for="(item, index) in tableState.overcharges" v-if="!tableState.loading" :key="index" justify="space-around"
        align="middle" :gutter="15" style="padding-bottom: 10px;"
      >
        <a-col :span="2">
          <Icon>
            <template #component>
              <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect y="0.5" width="8" height="8" rx="4" fill="#FFC107" />
              </svg>
            </template>
          </Icon>
        </a-col>
        <a-col :span="19">
          <a-typography-text>{{ item.text }}</a-typography-text>
        </a-col>
        <a-col :span="3">
          <a-popover placement="bottomRight" trigger="focus" arrow-point-at-center>
            <template #title>
              <a-space style="display: flex; justify-content: space-between; align-items: center;">
                <label>Possible Overcharge</label>
                <a-button type="ghost" size="small" :icon="h(CloseOutlined)" />
              </a-space>
            </template>
            <template #content>
              <a-space>
                <Icon style="display: flex; align-self: center;">
                  <template #component>
                    <svg width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect y="0.5" width="8" height="8" rx="4" fill="#FFC107" />
                    </svg>
                  </template>
                </Icon>
                <label>{{ getUniqueValues(item.key) }}</label>
              </a-space>
            </template>
            <a-button
              type="ghost" style="display: flex; align-items: center; justify-content: center;"
              @click="highlightCells(item.highlight)"
            >
              <template #icon>
                <ZoomInOutlined
                  :style="{ fontSize: '18px', color: JSON.stringify(item.highlight) === JSON.stringify(tableState.highlighted) ? '#a2a2a2' : '' }"
                />
              </template>
            </a-button>
          </a-popover>
        </a-col>
      </a-row>
      <a-row
        v-for="index in 4" v-else justify="space-around" align="middle" :gutter="15"
        style="padding-bottom: 10px;"
      >
        <a-col :span="3">
          <a-skeleton-avatar active size="small" shape="circle" />
        </a-col>
        <a-col :span="18">
          <a-skeleton-button active block />
        </a-col>
        <a-col :span="3">
          <a-skeleton-avatar active shape="circle" />
        </a-col>
      </a-row>
    </div>
  </a-card>
</template>
