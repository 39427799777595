import AdminLayout from "./AdminLayout.vue";
import HeadlessLayout from "./HeadlessLayout.vue";
import UserLayout from "./UserLayout.vue";
import CustomerDashboardLayout from "./CustomerDashboardLayout.vue"
import IssueLayout from "./IssueLayout.vue"
import BaseLayout from "./BaseLayout.vue";

export default {
    HeadlessLayout,
    UserLayout,
    AdminLayout,
    CustomerDashboardLayout,
    IssueLayout,
    BaseLayout
}