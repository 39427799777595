<template>
  <header class="header pb-5">
    <div class="d-flex justify-content-between align-items-end position-relative">
      <a class="logo" @click="toHome">
        <img
          alt="Patient Fairness Logo"
          src="https://irp.cdn-website.com/8bf01227/dms3rep/multi/PF-logo-name-2022-09-22.jpg"
          class="img-fluid"
        />
      </a>

      <nav class="navbar py-0">
        <button id="toggleBtn" class="navbar-toggler border-0 collapsed"  ref="collapse-btn" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
          <span class="bar1"></span>
          <span class="bar2"></span>
          <span class="bar3"></span>
        </button>
      </nav>

      <div class="collapse" id="navbarNavDropdown">
        <ul class="navbar-nav">
          <router-link
            :to="{name: 'admin.dashboard'}"
            custom
            v-slot="{ navigate }"
          >
            <li class="nav-item"><a class="nav-link" :class="{ 'active': $route.name === 'admin.dashboard' }" @click="navigate">Admin Dashboard</a></li>
          </router-link>
          <router-link
              :to="{ name: 'admin.reports' }"
              custom
              v-slot="{ navigate }"
          >
            <li class="nav-item"><a class="nav-link" :class="{ 'active': $route.name === 'admin.reports' }" @click="navigate">Reports</a></li>
          </router-link>
          <li class="nav-item"><button class="nav-link" @click="logout">Log Out</button></li>
        </ul>
      </div>
    </div>
  </header>
</template>

<script setup>
import { useMsal } from "../util/composition-api/useMsal.js";
import { authStore } from "../stores/authentication.js";
import { msalConfig } from "../util/plugins/authConfig.js";
import local from "../util/local-util.js";
import router from "../router/index.js";

router.beforeEach((to, from, next) => {
  const toggleBtn = document.getElementById('toggleBtn');
  const menu = document.getElementById('navbarNavDropdown');
  if (menu && menu.classList.contains('show')) {
    menu.classList.remove('show');
    toggleBtn.classList.add('collapsed')
    toggleBtn.ariaExpanded = "false";
  }
  next();
});

const toHome = () => {
  router.push('/');
  // const homeUrl = import.meta.env.VITE_AUTH_REDIRECT_URL;
};


const { instance } = useMsal();

const logout = () => {
  const accounts = instance.getAllAccounts();

  accounts.forEach(async (account) => {
    const currentAccount = instance.getAccountByHomeId(account.homeAccountId);
    await instance.logoutRedirect({
      account: currentAccount,
      postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri,
      onRedirectNavigate: () => {
        const store = authStore();

        store.$patch({
          token: '',
          user: {
            id: '',
            person_id: '',
            email: ''
          },
          issue: {
            id: '',
            title: ''
          }
        })

        local.remove('newCase');
        local.remove('issueId');
        local.remove('id');

        return true;
      }
    });
  })

}
</script>

<style lang="scss" scoped>
  @import "../scss/user-header.scss";
</style>