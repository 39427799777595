import api from '../api.js'
import ServiceResponse from './ServiceResponse.js'

const InsuranceSettingsService = {
  /**
   * Finds insurance settings for a given issue.
   *
   * @param {int} id
   * @returns {Promise<ServiceResponse>}
   */
  find: async (id) => {
    try {
      const { data } = await api({
        url: `/api/issues/${id}/insurance/letter/settings`,
      })

      return new ServiceResponse(true, data.data, null, null)
    }
    catch (e) {
      console.error('LODSettingsService.find: ', e)
      return new ServiceResponse(false, null, e.message, e.errors)
    }
  },

  /**
   * Saves ins for a given issue.
   *
   * @param {string} issueId
   * @param {object} formData
   * @return {ServiceResponse}
   */
  save: async (issueId, formData) => {
    try {
      const { data } = await api({
        url: `/api/issues/${issueId}/insurance/letter`,
        method: 'POST',
        formData,
      })
      return new ServiceResponse(true, data.data, null, null)
    }
    catch (e) {
      console.error('LODSettingsService.save: ', e)
      return new ServiceResponse(false, null, e.message, e.errors)
    }
  },

  /**
   * Retrieves a preview of a insurance letter.
   *
   * @param {number} issueId
   * @returns {object|Error} data
   */
  preview: async (issueId) => {
    try {
      const { data } = await api({
        url: `/api/letters/ins/preview?issue_id=${issueId}`,
        method: 'GET',
      })

      return new ServiceResponse(true, data.data, null, null)
    }
    catch (e) {
      console.error('LODSettingsService.preview: ', e)
      return new ServiceResponse(false, null, e.message, e.errors)
    }
  },

  /**
   * Save No Insurance Letter check
   * 
   * @param {string} issueId
   * @param {object} formData
   * @return {object|Error} data
   */
  saveNoInsuranceLetterCheck: async (issueId, formData) => {
    try {
      const { data } = await api({
        url: `/api/issues/${issueId}/insurance/letter/no-insurance`,
        method: 'POST',
        formData,
      })
      return new ServiceResponse(true, data.data, null, null)
    }
    catch (e) {
      console.error('LODSettingsService.save: ', e)
      return new ServiceResponse(false, null, e.message, e.errors)
    }
  },


  options: [
    // InP 01.1.4.1 
    {
      label: 'Ask Insurance Plan to confirm in-network benefits and stop the provider from balance billing for emergency services.',
      value: 'balance_bill_er_insured',
    },
    // InP 01.1.4.3 
    {
      label: "Ask Insurance Plan to confirm in-network benefits and stop the provider from balance billing for supplemental care"
        + " (e.g. anesthesia, radiology, pathology) who are not participating in a patient’s insurance network.",
      value: 'balance_bill_facility_par',
    },
    // InP 01.1.4.5 
    {
      label: "Ask Insurance Plan to stop the provider from balance billing for services rendered at a participating facility.",
      value: 'balance_bill_par_provider',
    },
    // InP 02.1 
    {
      label: "Ask Insurance Plan whether Provider was participating in Insurance Plan’s network.",
      value: 'confirm_is_provider_covered',
    },
    // InP 02.2 
    {
      label: "Ask Insurance Plan whether Provider is past the time limit to bill me for patient responsibilities, and if so, to ensure that Provider will rescind its bill to me.",
      value: 'timeliness_to_issue_bill',
    },
    // InP 04.1 
    {
      label: "Ask Insurance Plan whether it applied in-network benefits to the Claim.",
      value: 'confirm_network_prices',
    },
    // InP 04.2 
    {
      label: "Ask Insurance Plan to remove any charges for non-covered services from the amount I owe to the Provider under the explanation and benefits, remittance advice, and other guidance to the Provider.",
      value: 'insurance_owed_equals_patient_owed',
    },
    // InP 04.3 
    {
      label: "Ask Insurance Plan under what authority it made payments under my benefit plan directly to Provider.",
      value: 'did_not_agree_to_sign_benefits',
    },
  ],
  byValue: (value) => {
    return InsuranceSettingsService.options.find(o => o.value === value)
  },

}

export default InsuranceSettingsService
