import api from "../api.js";
import ServiceResponse from "./ServiceResponse.js";
import axios from 'axios';

export const getServiceLocations = async () => {
  try {
    const {data} = await api({
      url: `/api/location-types`,
      method: 'GET',
    });
    return data.data;
  } catch (error) {
    return {
      error
    }
  }
};

export const getFileDownload = async (bearerToken, issueId, docname, docId) => {
  const baseURL = import.meta.env.VITE_URL;
  const $axios = axios.create({
    baseURL
  });

  $axios({
    url: `/api/documents/${issueId}/${docId}`,
    method: 'GET',
    headers: {
      Authorization: `bearer ${bearerToken}`,
    },
    responseType: 'blob'
  })
  .then ((response) => {
    var FILE = window.URL.createObjectURL(new Blob([response.data])); 
    var docUrl = document.createElement('a');
    docUrl.href = FILE;
    docUrl.download = docname;
    document.body.appendChild(docUrl);
    docUrl.click();
    docUrl.remove();
  })
  .catch((error) => {
    console.error('Error downloading file:', error);
  })
};

export const getPmbaFileDownload = async (token, id, doc) => {
  axios({
    url: `${import.meta.env.VITE_URL}downloads/${id}`,
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: '*/*',
    },
    responseType: 'blob',
    data: {
      "path": doc.url
    },
  })
  .then ((response) => {
    var FILE = window.URL.createObjectURL(new Blob([response.data])); 
    var docUrl = document.createElement('a');
    docUrl.href = FILE;
    // docUrl.download = doc.docname + '.' + doc.url.split('.').pop();
    docUrl.download = doc.url;
    document.body.appendChild(docUrl);
    docUrl.click();
    docUrl.remove();
  })
  .catch((error) => {
    console.error('Error downloading file:', error);
  })
}

const IssueService = {
  /**
   * Get all issues
   *
   * @returns {Object|Error} data
   */
  all: async () => {
    try {
      const { data } = await api({
        url: `/api/issues`,
      });

      return new ServiceResponse(true, data.data, null, null);
    } catch (e) {
      console.error("Failed get all issues: ", e);
      return new ServiceResponse(false, null, e.message, e.errors);
    }
  },

  /**
   * Get issue by slug
   * @param {string} slug
   * @returns {Object|Error} data
   */
  bySlug: async (slug) => {
    try {
      const { data } = await api({
        url: `/api/issues/by-slug/${slug}`,
      });

      return new ServiceResponse(true, data.data, null, null);
    } catch (e) {
      console.error("IssueService.bySlug: ", e);
      return new ServiceResponse(false, null, e.message, e.errors);
    }
  },

  /**
   * Find a single issue
   *
   * @param {int} id
   * @returns {Promise<ServiceResponse>}
   */
  find: async (id) => {
    try {
      const { data } = await api({
        url: `/api/issues/${id}`,
      });

      return new ServiceResponse(true, data.data, null, null);
    } catch (e) {

      console.error("IssueService.find: ", e);
      return new ServiceResponse(false, null, e.message, e.errors);
    }
  },

  /**
   * Send new case
   * @param {object} body
   * @returns {Object|Error} data
   */
  new: async (body) => {
    try {
      const { data } = await api({
        url: '/api/issues',
        method: 'POST',
        body,
      });
      return new ServiceResponse(true, data.data, null, null);
    }
    catch (e) {
      console.error("Failed to send new case: ", e);

      return new ServiceResponse(false, null, e.message, e.errors);
    }
  },

  /**
   * Updates the case with the given slug.
   *
   * @param {string} slug
   * @param {object} body
   * @returns {Object|Error} data
   */
  update: async (slug, body) => {
    try {
      const { data } = await api({
        url: `/api/issues/${slug}`,
        method: 'PATCH',
        body,
      });
      return new ServiceResponse(true, data.data, null, null);
    }
    catch (e) {
      console.error("Failed to update case: ", e);

      return new ServiceResponse(false, null, e.message, e.errors);
    }
  },

  /**
   * Send Preview LOD
   *
   * @param {number} issueId
   * @returns {Object|Error} data
   */
  sendPreviewLOD: async (issueId) => {
    try {
      // send the updated LOD
      const { data } = await api({
        url: `/api/issues/${issueId}/lod/send`,
        method: 'POST'
      });
      return new ServiceResponse(true, data, null, null);
    }
    catch (e) {
      console.error("Failed to send updated LOD: ", e);

      return new ServiceResponse(false, null, e.message, e.errors);
    }
  },

  /**
   * @deprecated - moved to AuthorizationService.lod
   * 
   * Async function to authorize the issue with the given ID.
   *
   * @param {number} issueId
   * @return {Object|Error} data
   */
  authorize: async (issueId) => {
    try {
      // send the updated LOD
      const { data } = await api({
        url: `/api/issues/${issueId}/authorize`,
        method: 'POST'
      });
      return new ServiceResponse(true, data.data, null, null);
    }
    catch (e) {
      console.error("Failed to authorize: ", e);

      return new ServiceResponse(false, null, e.message, e.errors);
    }
  },

  saveBilledPerson: async (issue_id, body, method) => {
    try {
      const { data } = await api({
        url: `/api/issues/${issue_id}/who-are-you`,
        method,
        body
      });
      return new ServiceResponse(true, data.data, null, null);
    }
    catch (e) {
      console.error("IssueService.saveBilledPerson: ", e);
      return new ServiceResponse(false, null, e.response.data.message || e.message, e.response.data.data);
    }
  },

  savePatient: async (issue_id, body, method) => {
    try {
      const { data } = await api({
        url: `/api/issues/${issue_id}/who-is-the-patient`,
        method,
        body
      });
      return new ServiceResponse(true, data.data, null, null);
    }
    catch (e) {
      console.error("IssueService.savePatient: ", e);
      return new ServiceResponse(false, null, e.response.data.message || e.message, e.response.data.data);
    }
  },

  saveProviderDetails: async (issue_id, body, method) => {
    try {
      const { data } = await api({
        url: `/api/issues/${issue_id}/who-is-the-bill-from`,
        method,
        body
      });
      return new ServiceResponse(true, data.data, null, null);
    }
    catch (e) {
      console.error("IssueService.saveProviderDetails: ", e);
      return new ServiceResponse(false, null, e.response.data.message || e.message, e.response.data.data);
    }
  },

  saveMedicalBillDetails: async (issue_id, body) => {
    try {
      const { data } = await api({
        url: `/api/issues/${issue_id}/what-does-the-bill-say`,
        method: 'POST',
        formData: body
      });
      return new ServiceResponse(true, data.data, null, null);
    }
    catch (e) {
      console.error("IssueService.saveMedicalBillDetails: ", e);
      return new ServiceResponse(false, null, e.message, e.errors);
    }
  },

  saveLookAtBill: async (issue_id, body) => {
    try {
      const { data } = await api({
        url: `/api/issues/${issue_id}/lets-look-at-your-bill`,
        method: 'POST',
        body
      });
      return new ServiceResponse(true, data.data, null, null);
    }
    catch (e) {
      console.error("IssueService.saveLookAtBill: ", e);
      return new ServiceResponse(false, null, e.message, e.errors);
    }
  },

  findWithAllDetails: async (id) => {
    try {
      const { data } = await api({
        url: `/api/admin/issues/${id}`,
      });

      return new ServiceResponse(true, data.data, null, null);
    } catch (e) {

      console.error("IssueService.findWithAllDetails: ", e);
      return new ServiceResponse(false, null, e.message, e.errors);
    }
  },

  problems: async (issueId) => {
    try {
      const { data } = await api({
        url: `/api/issues/${issueId}/lod/problems`,
        method: "GET",
      });

      return new ServiceResponse(true, data.data, null, null);
    } catch (e) {
      console.error("LodProblemsService.concerns: ", e);
      return new ServiceResponse(false, null, e.message, e.errors);
    }
  },
}

export default IssueService;