import api from '@/util/api.js'
import ServiceResponse from '@/util/services/ServiceResponse.js'

const FastTrackService = {
  /**
   * Process fast track
   *  
   * @param {issueId} issueId - the data to be saved
   * @param {data} data - the data to be saved
   * @return {Promise<ServiceResponse>} a Promise that resolves to a ServiceResponse
   */
  process: async (issueId: number, data: any): Promise<ServiceResponse> => {
    try {
      const { data: response } = await api({
        url: `/api/issues/${issueId}/fast-track/process`,
        method: 'POST',
        body: data,
      })
      return new ServiceResponse(true, response.data, response.message, null)
    }
    catch (e: any) {
      console.error('FastTrackService.process: ', e)
      return new ServiceResponse(false, null, e.message, e.errors)
    }
  },

  /**
   * Submit fast track to progress
   *
   * @param {issueId} issueId - the data to be saved
   * @return {Promise<ServiceResponse>} a Promise that resolves to a ServiceResponse
   */
  submit: async (issueId: number): Promise<ServiceResponse> => {
    try {
      const { data } = await api({
        url: `/api/issues/${issueId}/fast-track/submit`,
        method: 'POST',
      })

      return new ServiceResponse(true, data.data, null, null)
    }
    catch (e: any) {
      console.error('FastTrackService.submit: ', e)
      return new ServiceResponse(false, null, e.message, e.errors)
    }
  },
}

export default FastTrackService
