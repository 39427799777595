import { LogLevel, PublicClientApplication } from '@azure/msal-browser';

const authUrl = `https://patientfairnessauth.b2clogin.com/${import.meta.env.VITE_TENANT_ID}/B2C_1_SignUpSignIn`;
export const tokenUrl = `https://patientfairnessauth.b2clogin.com/patientfairnessauth.onmicrosoft.com/B2C_1_SignUpSignIn
/oauth2/v2.0/token?client_id=${import.meta.env.VITE_CLIENT_ID}&response_type=code`;
// Config object to be passed to Msal on creation

export const msalConfig = {
  auth: {
    clientId: import.meta.env.VITE_CLIENT_ID,
    authority: authUrl,
    knownAuthorities: [ `patientfairnessauth.b2clogin.com` ],
    redirectUri: `${import.meta.env.VITE_AUTH_REDIRECT_URL}`,
    // For local testing
    // redirectUri: 'http://localhost:5173/',
    postLogoutRedirectUri: '/patient-fairness',
    navigateToLoginRequestUrl: true
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
    secureCookies: false
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
      logLevel: LogLevel.Verbose
    }
  }
};

export const msalInstance = new PublicClientApplication(msalConfig);

export const params = {
  authority: authUrl,
  scopes: [ 'https://patientfairnessauth.onmicrosoft.com/tasks-api' ]
};

export const altConfig = {
  auth: {
    clientId: import.meta.env.VITE_CLIENT_ID,
    authority: authUrl,
    redirectUri: `${import.meta.env.VITE_AUTH_REDIRECT_URL}`,
  }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: [ 'https://patientfairnessauth.onmicrosoft.com/tasks-api' ],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};