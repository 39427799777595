<template>
<!--    <div class="container py-3 py-lg-5 px-4">-->
  <component :is="layout || 'div'">
    <router-view/>
  </component>

<!--    </div>-->
</template>
<script setup>
import { provide, shallowRef } from "vue";
import layouts from "./layouts/layouts.js";
import router from "./router/index.js";
import UserLayout from "./layouts/UserLayout.vue";

const layout = shallowRef('div');

router.afterEach((to) => {
  layout.value = layouts[to.meta.layout] || UserLayout;
})

provide('app.layout', layout);
</script>
<style scoped></style>
