<script lang="ts" setup>
import Icon, { CheckCircleOutlined, DoubleRightOutlined, DownOutlined, LockOutlined } from '@ant-design/icons-vue'
import { computed, reactive } from 'vue'
import type { Activity, ProgressData, TreeItem } from '../../types/progress'
import { findCardTitle, findRouteByActivity, findRouteByStep, findTitle } from '../../utils/progress'
import router from '@/router'

const props = defineProps(['data', 'loading'])

const state = reactive({
  showBody: false,
  activeCard: -1,
  slug: router.currentRoute.value.params?.slug,
  progress: computed(() => props.data?.progress?.stages ?? []),
})

function goToSection(index: number) {
  state.showBody = true
  state.activeCard = index
}

function mapActivities(activities: Activity[]): TreeItem[] {
  return activities
    .filter(activity => activity.status !== 'skipped')
    .map((activity, index) => {
      const children = activity.steps ? mapActivities(activity.steps) : []
      children.forEach((child) => {
        child.parameters = {
          ...child.parameters,
          ...activity.parameters,
        };
      })
      return {
        title: findTitle(activity.slug)?.title ?? activity.slug,
        key: `${activity.slug}-${index}`,
        parameters: activity.parameters,
        status: activity.status,
        type: activity.type,
        id: activity.id,
        children,
      }
    })
}

// map state.progress to treeData
const treeData = computed(() => {
  return state.progress.map((progress: ProgressData) => {
    const data = mapActivities(progress.activities)
    return {
      progress,
      data,
    }
  })
})

function goToPage(title: string, id: number, status: string, parameters: any): void {
  if ((title !== 'Screening' && title !== 'Create a Case') || !state.slug) {
    if (status === 'started' || status === 'completed') {
      if (findRouteByActivity(title) && id)
        router.push({ name: findRouteByActivity(title), params: { slug: state.slug, id: Object.values(parameters)[0] } })
      else if (findRouteByStep(title))
        router.push({ name: findRouteByStep(title), params: { slug: state.slug, id: Object.values(parameters)[0] } })
    }
  }
}
</script>

<template>
  <a-card class="progress-card">
    <template #title>
      <div class="progress-card__title">
        <a-card
          v-for="(progressData, index) in treeData" :key="index" size="small" class="progress-card__inner"
          :style="{ borderColor: state.activeCard === index ? '#54C860' : '' }" @click="goToSection(index)"
        >
          <a-row :wrap="false" :gutter="[12, 0]" class="progress-card__inner-row">
            <a-col flex="none">
              <Icon v-if="progressData.progress.active">
                <template #component>
                  <img src="/icons/circle-orange.svg" alt="O">
                </template>
              </Icon>
              <CheckCircleOutlined v-else :style="{ fontSize: '24px', color: '#54C860' }" />
            </a-col>
            <a-col flex="auto">
              <label style="white-space: normal;">
                {{ findCardTitle(progressData.progress.id)?.title ?? progressData.progress.id }}
              </label>
            </a-col>
          </a-row>
        </a-card>
      </div>
    </template>
    <template v-if="state.showBody" #default>
      <a-directory-tree :tree-data="treeData[state.activeCard].data" :selectable="false" :show-icon="false" block-node>
        <template #switcherIcon="{ switcherCls }">
          <DownOutlined :class="switcherCls" />
        </template>
        <template #title="{ title, status, id, children, parameters }">
          <a-row :wrap="false" :gutter="[12, 0]" align="middle" :style="{ marginLeft: !children.length ? '-28px' : '-4px' }" @click="!children.length && goToPage(title, id, status, parameters)">
            <a-col v-if="!children.length" flex="none">
              <Icon>
                <template #component>
                  <img width="12" height="12" src="/icons/bullet.svg" alt="o" style="margin-top: 7px;">
                </template>
              </Icon>
            </a-col>
            <a-col flex="auto">
              {{ title }}
            </a-col>
            <a-col flex="none">
              <template v-if="status === 'completed'">
                <CheckCircleOutlined :style="{ fontSize: '24px', color: '#54C860' }" />
              </template>
              <template v-else-if="status === 'started'">
                <Icon>
                  <template #component>
                    <img src="/icons/circle-orange.svg" alt="O" style="margin-right: -2px">
                  </template>
                </Icon>
              </template>
              <template v-else-if="status === 'skipped'">
                <DoubleRightOutlined :style="{ fontSize: '24px', color: '#707070' }" />
              </template>
              <template v-else>
                <LockOutlined :style="{ fontSize: '24px', color: '#707070' }" />
              </template>
            </a-col>
          </a-row>
        </template>
      </a-directory-tree>
    </template>
  </a-card>
</template>

<style lang="scss" scoped>
.progress-card {
  padding: 0;
  border-radius: 0;
  border: 0;

  &__title {
    overflow-x: auto;
    white-space: nowrap;
    margin: 16px -8px;
  }

  &__inner {
    display: inline-block;
    margin: 0 8px;

    &-row {
      width: 180px;
      height: 30px;
      display: flex;
      align-items: center;
    }
  }
}
</style>
