import local from "./local-util.js";
import { jwt } from "./jwt.js";

export const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

export const getTokenSetLocal = () => {
  const allTokens = local.getAllStorage('secret');
  if (Object.entries(allTokens).length > 0) {
    let accessToken = Object.values(allTokens)
      .filter((t) => t.credentialType === 'IdToken')[0]['secret'];
    return {
      accessToken,
      jwt: jwt(accessToken)
    }
  }
  return {
    accessToken: '',
    jwt: ''
  }
};
