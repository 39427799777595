import type { RouteRecordRaw, Router } from 'vue-router'

const moduleRoute: RouteRecordRaw = {
  path: '/admin',
  children: [
    {
      path: 'dashboard',
      name: 'admin.dashboard',
      component: () => import('../pages/Dashboard.vue'),
      meta: {
        requiresAuth: true,
        title: 'Admin Dashboard',
        layout: 'AdminLayout',
      },
    },
    {
      path: 'reports',
      children: [
        {
          path: '',
          name: 'admin.reports',
          component: () => import('../pages/reports/Index.vue'),
          meta: {
            title: 'Admin Reports',
          },
        },
      ],
    },
  ],
  meta: {
    requiresAuth: true,
    layout: 'AdminLayout',
  },
}

export default (router: Router) => {
  router.addRoute(moduleRoute)
}
