const GoogleTracking = {
  gtag: null, // Declare gtag as a property

  loadGTM: () => {
    const script = document.createElement('script')
    script.async = true
    script.src = `https://www.googletagmanager.com/gtm.js?id=${import.meta.env.VITE_GTM}`
    document.head.appendChild(script)
  },

  loadGoogleTag: () => {
    const script = document.createElement('script')
    script.async = true
    script.src = `https://www.googletagmanager.com/gtag/js?id=${import.meta.env.VITE_GTAG_ID}`
    document.head.appendChild(script)
  },

  initializeGoogleTag: () => {
    window.dataLayer = window.dataLayer || []
    GoogleTracking.gtag = function () {
      dataLayer.push(arguments)
    }

    // Assign gtag to window object for global access
    window.gtag = GoogleTracking.gtag

    // Call gtag to initialize
    GoogleTracking.gtag('js', new Date())
    GoogleTracking.gtag('config', import.meta.env.VITE_GTAG_ID)
  },

  loadGTMNoScript: () => {
    const noScript = document.createElement('noscript')
    const iframe = document.createElement('iframe')

    iframe.src = `https://www.googletagmanager.com/ns.html?id=${import.meta.env.VITE_GTM}`
    iframe.height = '0'
    iframe.width = '0'
    iframe.style.display = 'none'
    iframe.style.visibility = 'hidden'

    noScript.appendChild(iframe)
    document.body.insertBefore(noScript, document.body.firstChild)
  },

  enable: () => {
    GoogleTracking.loadGTM()
    GoogleTracking.loadGoogleTag()
    GoogleTracking.initializeGoogleTag()
    GoogleTracking.loadGTMNoScript()
  },
}

export default GoogleTracking
