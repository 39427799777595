import { notification } from "ant-design-vue";

export const handleError = (error) => {
  notification.error({
    message: "Error",
    description: error.message,
    placement: "topRight",
  });
};

export const handleSuccess = () => {
  notification.success({
    message: "Success",
    description: "Issue loaded successfully",
    placement: "topRight",
  });
};

/**
 * Creates a custom ant design notification with the given type, message, and description.
 *
 * @param {string} type - The type of the notification: success, error, warning, info
 * @param {string} message - The message to display in the notification title
 * @param {string} description - The description to display in the notification content
 */
export const customNotification = (type, message, description) => {
  notification[type]({
    message: message,
    description: description,
    placement: "topRight",
  })
}