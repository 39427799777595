import type { RouteRecordRaw, Router } from 'vue-router'
import { responseRoutes } from './responseRoutes'
import { proposalRoutes } from './proposalRoutes'

const moduleRoute: RouteRecordRaw = {
  path: '/issues/:slug/',
  meta: { title: "Case #" },
  children: [
    ...responseRoutes,
    ...proposalRoutes,
  ],
}

export default (router: Router) => {
  router.addRoute(moduleRoute)
}
